import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import CreateProjectWizard from '../components/CreateProjectWizard'
import { useDispatch, useSelector } from 'react-redux'
import { getProject } from '../components/project/projectSlice'
import { fetchProject, modifyProject } from '../api/project'

export default function ProjectEditPage({
  match: {
    params: {
      projectId,
    },
  },
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const project = useSelector(getProject)

  useEffect(() => {
    dispatch(fetchProject(projectId))
  }, [dispatch, projectId])

  function onFinish(e, data) {
    const { name, toolIds } = data
    const tools = toolIds.map(toolId => ({ id: toolId }))
    dispatch(modifyProject(project, { name, tools }))
      .then((res) => history.push(`/projects/${res.id}`))
  }

  if (project.id !== projectId) return null

  return (
    <CreateProjectWizard
      onFinish={onFinish}
      initialData={{
        name: project.name,
        tools: { rows: [], columns: [] },
      }}
    />
  )
}

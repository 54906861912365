import {createSlice} from "@reduxjs/toolkit"

export const profilesSlice = createSlice({
  name: 'profiles',
  initialState: {
    currentProfile: {}
  },
  reducers: {
    /* TODO: Think how is the best way to store many profiles
    addProfile(state, action) {

      state.profiles.push(action.payload)
    },
     */
    setCurrentProfile(state, action) {
      state.currentProfile = Object.assign(state.currentProfile, action.payload)
    },
    /*
    setProfile(state, action) {
      const newProfile = action.payload
      const profileIndex = state.profiles.findIndex(profile => profile.id === newProfile.id)

      if (profileIndex > -1) {
        state.profiles[profileIndex] = newProfile
      } else (
        this.addProfile(state, action)
      )
    }
     */
  }
})

export const { addProfile, setCurrentProfile, setProfile } = profilesSlice.actions

export const getProfiles = state => state.profiles
export const getCurrentProfile = state => { // experiment: this could be redundant
  return state.profiles.currentProfile
}
export const getProfilesById = state => {
  const profiles = getProfiles(state)
  return profiles.reduce((hub, profile) => {
    const {id} = profile
    hub[id] = profile
    return hub
  })
}
export const getProfileProjects = state => {
  if (state.profiles && state.profiles.currentProfile.id !== undefined && state.profiles.currentProfile.id !== null) {
    return state.profiles.currentProfile.projects
  }
  return []
}

export default profilesSlice.reducer
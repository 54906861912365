import { createSlice } from '@reduxjs/toolkit'

export const projectSlice = createSlice({
  name: 'project',
  initialState: {},
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
    updateProjectToolVersion(state, action) {
      const { id, version, input, output } = action.payload
      const tool = state.tools.find(tool => tool.id === id)
      if (!tool) return

      const oldVersionId = tool.version.id
      const { input: oldInput, output: oldOutput } = tool

      const oldVersionIndex = tool.versions
        .findIndex(version => version.id === oldVersionId)
      if (oldVersionIndex >= 0) {
        tool.versions[oldVersionIndex].input = oldInput
        tool.versions[oldVersionIndex].output = oldOutput
      }
      tool.version = version
      tool.input = input
      tool.output = output
    },
    updateProjectResult(state, action) {
      const { resultId, output } = action.payload
      const result = state.results.find(result => result.id === resultId)
      if (!result) return
      const newOutput = { ...result.output, ...output }
      result.output = newOutput
    },
    updateProjectDatasetValue(state, action) {
      const { datasetIds, value } = action.payload
      const { datasets } = state
      for (let i = 0; i < datasetIds.length; i++) {
        const dataset = datasets.find(dataset => dataset.id === datasetIds[i])
        if (!dataset) continue
        if (dataset.data.value) continue // value already loaded
        dataset.data.value = value
      }
    },
    createResult(state, action) {
      state.results.unshift(action.payload)
    },
    createProjectDataset(state, action) {
      state.datasets.unshift(action.payload)
    },
    createProjectDatasetVersion(state, action) {
      const { id: datasetId, version } = action.payload
      const dataset = state.datasets.find(dataset => dataset.id === datasetId)
      if (!dataset) {
        console.log(datasetId)
        return
      }
      dataset.versions.unshift(version)
      dataset.version = version
    },
    setProjectResultFileIdSummary(state, action) {
      const { fileId, fileSummary } = action.payload
      const results = state.results
      results.forEach(result => {
        ['input', 'output'].forEach(variableType => {
          const x = result[variableType] || {}
          const variables = x['variables'] || []
          variables.forEach(variable => {
            const data = variable.data
            if (! ('file' in data)) return
            if (data.file.id === fileId) {
              data.file.summary = { ...fileSummary }
            }
          })
        })
      })
    },
  },
})

export const {
  set: setProject,
  updateProjectToolVersion,
  updateProjectResult,
  createResult,
  updateProjectDatasetValue,
  createProjectDataset,
  createProjectDatasetVersion,
  setProjectResultFileIdSummary,
} = projectSlice.actions

export const getProject = state => state.project

export default projectSlice.reducer

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'

import { getLatestProjects } from '../components/projects/projectsSlice'

import ContentBox from '../components/ContentBox'
import CreateProjectButton from '../components/CreateProjectButton'
import ProjectCardGridView from '../components/ProjectCardGridView'

import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'

import { fetchProjects } from '../api/projects'

import {
  PROJECTS_PATH,
} from '../constants'

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    margin: theme.spacing(2, 0),
  },
  toggleButton: {
    padding: theme.spacing(0, 3),
  },
  createProjectContainer: {
    border: 'dashed  2px black',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default function DashboardPage(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [latestGridList, setLatestGridList] = useState('projects')
  const projects = useSelector(getLatestProjects)

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  return (
    <>
      <MainSection display='flex' flexDirection='column'>
        <HeaderSection title='Dashboard'>
          search
        </HeaderSection>
        <Grid container spacing={4} style={{ flexGrow: 1, overflow: 'hidden' }}>
          <Grid item xs={6} style={{ height: '45%' }}>
            <ContentBox display='flex' flexDirection='column' style={{ overflow: 'hidden' }}>
              <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
                <Typography component='h1' variant='h5'>Latest Opened</Typography>
                <Button
                  size='small'
                  component={RouterLink}
                  to={PROJECTS_PATH}
                  color='primary'
                >
                  List View
                </Button>
              </Box>
              <ToggleButtonGroup
                exclusive
                value={latestGridList}
                onChange={(e, newLatestGridList) => setLatestGridList(newLatestGridList)}
                size='small'
                className={classes.buttonGroup}
              >
                <ToggleButton value='projects' classes={{ root: classes.toggleButton }}>
                  Projects
                </ToggleButton>
                <ToggleButton value='reports' classes={{ root: classes.toggleButton }}>
                  Reports
                </ToggleButton>
              </ToggleButtonGroup>
              <Grid container spacing={1} style={{ flexGrow: 1, overflow: 'hidden' }}>
                { latestGridList === 'projects' && projects &&
                  projects.map(project => (
                    <Grid item xs={4} style={{ height: '100%' }} key={project.id}>
                      <ProjectCardGridView project={project} />
                    </Grid>))
                }
              </Grid>

            </ContentBox>
          </Grid>
          <Grid item xs={6} style={{ height: '45%' }}>
            <ContentBox style={{ overflow: 'hidden' }}>
              <Box display='flex' justifyContent='space-between'>
                <Typography component='h1' variant='h5'>Featured Project</Typography>
              </Box>
            </ContentBox>
          </Grid>
          <Grid item xs={12} style={{ height: '55%' }}>
            <ContentBox>
              <div className={classes.createProjectContainer}>
                <CreateProjectButton />
              </div>
            </ContentBox>
          </Grid>
        </Grid>

      </MainSection>
    </>
  )
}

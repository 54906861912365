import React from 'react'
import { useDropzone } from 'react-dropzone'

export default function UploadZone({
  Component,
  onDrop,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop, noClick: true, noKeyboard: true })
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Component isDragActive={isDragActive} />
    </div>
  )
}

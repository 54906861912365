import React, { useEffect }  from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
// import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
// import moment from 'moment'
// import IconButton from '@material-ui/core/IconButton'
// import Menu from '@material-ui/core/Menu'
// import MenuItem from '@material-ui/core/MenuItem'
// import MoreVertIcon from '@material-ui/icons/MoreVert'

import EmptyProjectsPage from './EmptyProjectsPage'
import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'

import { DataGrid } from '@material-ui/data-grid'
// import { Link } from 'react-router-dom'

import '../styles/ProjectsPage.css'
import { getProjects } from '../components/projects/projectsSlice'
import {
  // deleteProject,
  fetchProjects,
} from '../api/projects'
import { PROJECT_WIZARD_PATH } from '../constants'
// import { SERVER_URL } from '../constants'

const useStyles = makeStyles(theme => ({
  button: {
    'color': 'white',
    'backgroundColor': '#4880ff',
    'borderRadius': '25px',
    'textTransform': 'none',
    'fontWeight': 'bold',
    'fontSize': '1rem',
    'padding': '8px 56px 8px 56px',
    '&:hover': {
      backgroundColor: '#2256ce',
    },
  },
  image: {
    width: '30vw',
    paddingBottom: '.5em',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  noPadding: {
    padding: '0 !important',
  },
}))

/*
const BorderLinearProgress = withStyles((theme) => {
  return ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  })
})(LinearProgress)
*/

export default function ProjectsPage(props) {
  /*
   * Define state variable projects, setProjects
   * useEffect => fetch SERVER_URL + /projects.json
  * setProjects(responseJson)
  */

  // const [projects, setProjects] = useState([])
  const { projects, isLoaded: isProjectsLoaded } = useSelector(getProjects)
  const dispatch = useDispatch()
  const history = useHistory()
	// const [anchorEl, setAnchorEl] = React.useState(null)
  // const open = Boolean(anchorEl)

  useEffect(() => {
    /*const f = async () => {
      const url = SERVER_URL + '/projects.json'

      const response = await fetch(url, {
        'credentials': 'include',
      })
      const responseJson = await response.json()
      setProjects(responseJson)
      console.log('THESE ARE THE PROJECTSSSS', responseJson)
    }
    f()*/
    dispatch(fetchProjects())
  }, [dispatch])

  const classes = useStyles()

  /*
  const handleValue = (value) => {
    return {
      'width': '60%',
      'marginRight': '10px',
      '.MuiLinearProgressBar': {
        backgroundColor: 'red !important',
      },
    }
  }

  const handleTimestamp = value => {
    const d = new Date(Date.parse(value))
    return (moment(d).format('MMM D, YYYY'))
  }
  */

  function handleChangeHistory(id) {
    history.push(`/projects/${id}`)
  }

  const rows = projects

  /*
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    event.stopPropagation()
  }

  const handleClose = (event) => {
    setAnchorEl(null)
    event.stopPropagation()
  }

  const handleDelete = (event, id) => {
    dispatch(deleteProject(id))
    handleClose(event)
  }
  */

  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'button',
      headerName: ' ',
      flex: 0,
      disableClickEventBubbling: true,
      cellClassName: () => {
        return clsx(classes.noPadding)
      },
      /*
      renderCell: params => {
        const id = params.row.id
        return (
          <div
            style={{ width: '100%' }}
            onClick={(e) => {
              handleChangeHistory(id)
            }}
          >
						<IconButton
							onClick={handleClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={open}
							onClose={handleClose}
						>
              <MenuItem onClick={(e) => {handleDelete(e, id)}}>
								Delete
							</MenuItem>
						</Menu>
          </div>
        )
      },
      */
    },
    /*{
      field: 'progress',
      headerName: 'Progress',
      width: 600,
      renderCell: params => (
        <div style={{ width: '80%', display: 'flex', alignItems: 'center' }}>
          <BorderLinearProgress style={handleValue(params.value)} variant="determinate" value={params.value} />
          {params.value}%
        </div>
      ),
    },
    {
      field: 'modificationTimeStamp',
      headerName: 'Last modified',
      width: 200,
      renderCell: params => (
        <div>
          {handleTimestamp(params.value)}
        </div>
      ),
    },*/
  ]

  const handleRowClick = params => {
    const id = params.row.id
    handleChangeHistory(id)
  }

  if (!isProjectsLoaded) {
    return <MainSection />
  }

  return (
    <>
      { projects.length > 0
        ? <MainSection display='flex' flexDirection='column'>
          <HeaderSection title='Projects'>
            <Button
              variant='contained'
              disableElevation
              className={classes.button}
              component={RouterLink}
              to={PROJECT_WIZARD_PATH}
            >
              Start a Project
            </Button>
          </HeaderSection>
          {/* TODO: Consider using Box as per Polina's code */}
          <div style={{ height: '100vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              rowHeight='75'
              onRowClick={handleRowClick}
            />
          </div>
        </MainSection>
        : <EmptyProjectsPage />
      }
    </>
  )
}

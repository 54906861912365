import { apiRequest } from './index'

export const addDataset = ({
  name,
  view,
  type,
  size,
  projects,
}) => async dispatch => {
  return apiRequest({
    path: '/datasets.json',
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, view, type, size, projects }),
    },
  }).then(res => {
    return res
  })
}

export const fetchDatasetValue = () => async dispatch => {
}

export const fetchDatasets = () => async dispatch => {
}


import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid } from '@material-ui/data-grid'
import CancelIcon from '@material-ui/icons/Cancel'
// import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
// import MenuItem from '@material-ui/core/MenuItem'
// import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { fetchTools } from '../api/tools'

import { getTools } from '../components/tools/toolsSlice'
import Stepper from './Stepper'

import { ROOT_PATH } from '../constants'

/*
const useCategoryStyles = makeStyles(theme => ({
  select: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  icon: {
    color: 'white',
  },
}))

function CategoryField(props) {
  const classes = useCategoryStyles()
  return (
    <div style={{ height: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid rgba(255, 255, 255, .5)' }}>
      <div style={{ width: '30%' }}>
        <Typography
          variant='button'
          align='center'
          display='block'
          gutterBottom
        >
          Category of the Project
        </Typography>
        <FormControl style={{ display: 'block' }}>
          <Select
            variant='outlined'
            value={10}
            style={{ width: '100%', background: 'rgba(255, 255, 255, .5)', color: 'white' }}
            className={classes.select}
            classes={{
              icon: classes.icon,
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={10}>Agriculture</MenuItem>
          </Select>
        </FormControl>
      </div>
    </div>
  )
}
*/

const useWizardStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(40deg, #5b22da 40%, #5d61f6)',
    color: 'white',
  },
  textFieldRoot: {
    color: 'white',
    borderBottom: '2px solid rgb(255, 255, 255) !important',
    fontSize: theme.spacing(3),
  },
}))

function getTableData(tools) {
  const rows = tools.map(tool => {
    const {
      id,
      name: toolName,
      // description,
      creator,
    } = tool
    const creatorName = creator.name
    return {
      id,
      toolName,
      // description,
      creatorName,
    }
  })

  const columns = [
    { field: 'toolName', headerName: 'Tool', flex: 2 },
    // { field: 'description', headerName: 'Description', flex: 1 },
    { field: 'creatorName', headerName: 'Creator', flex: 1 },
  ]
  return { rows, columns }
}

export default function CreateProjectWizard(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const tools = useSelector(getTools)
  const classes = useWizardStyles()
  const [data, setData] = useState(() => {
    if (!props.initialData) {
      return {
        name: '',
        tools: {
          rows: [],
          columns: [],
        },
      }
    }
    return props.initialData
  })
  const { onFinish = () => {} } = props

  useEffect(() => {
    dispatch(fetchTools())
  }, [dispatch])

  useEffect(() => {
    if (!tools.length) return
    const { rows, columns } = getTableData(tools)
    // console.log(tools)
    // console.log(columns)
    // console.log(rows)
    updateData('tools', { rows, columns })
  }, [tools])

  function updateData(key, value) {
    setData(prevState => ({ ...prevState, [key]: value }))
  }

  return (
    <div className={classes.root}>
      <div style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => {
        if (history.length === 2) {
          history.push(ROOT_PATH)
        } else {
          history.goBack()
        }
      }}>
        <IconButton color='inherit' style={{ fontSize: '24px' }}>
          <CancelIcon />
        </IconButton>
      </div>
      <Stepper
        onBack={() => updateData('toolIds', [])}
        onFinish={(e) => {
          onFinish(e, data)
        }}
      >
        <div>
          <Typography gutterBottom component='h1' variant='h4' align='center'>
            Pick a name for your next project
          </Typography>
          <TextField
            fullWidth
            value={data.name || ''}
            InputProps={{ classes: { root: classes.textFieldRoot } }}
            onChange={(e) => {
              const value = e.target.value
              updateData('name', value)
            }}
          />
        </div>
        <div>
          <Typography gutterBottom component='h1' variant='h4' align='center'>
            Pick the tools you will need from the list
          </Typography>
          <div style={{ height: 450, background: 'white', borderRadius: '4px' }}>
            <DataGrid
              checkboxSelection
              rows={data.tools.rows}
              columns={data.tools.columns}
              onSelectionChange={params => {
                // console.log(params)
                const { rowIds } = params
                // const selectedToolIds = selectedTools.map(tool => tool.id)
                updateData('toolIds', rowIds)
              }}
              pageSize={5}
            />
          </div>
        </div>
      </Stepper>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import FilledInput from '@material-ui/core/FilledInput'

export default function BlockTextField({
  value,
  setData,
  ...etc
}) {
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    setLocalValue(value)
  }, [value])
  
  const props = setData ? {
    onChange: event => setLocalValue(event.target.value),
    onBlur: event => setData({ value: localValue }),
  } : {
    readOnly: true,
  }
  return (
    <FilledInput
      value={localValue}
      fullWidth
      {...props}
      {...etc}
    />
  )
}

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
// TODO: Merge legacies/Block with components/Block
import Block from '../legacies/Block'

import {
  SERVER_URL,
} from '../constants'

export default function PrintDocumentPage() {
  const { printId, documentIndex } = useParams()
  const [printDocument, setPrintDocument] = useState()

  async function fetchPrintDocument(printId, documentIndex) {
    const url = [
      SERVER_URL, 'prints', printId, 'documents', documentIndex,
    ].join('/') + '.json'
    const response = await fetch(url, { credentials: 'include' })
    const printDocument = await response.json()
    setPrintDocument(printDocument)
  }

  useEffect(() => {
    fetchPrintDocument(printId, documentIndex)
  }, [printId, documentIndex])

  if (!printDocument) return null
  const { blocks, styles } = printDocument

  return (
    <div>
      <style>
        {styles.join('\n')}
      </style>
      {blocks.map((block, index) => (
        <Block
          key={index}
          id={block.id}
          name={block.name}
          view={block.view}
          data={block.data}
          // {...block}
          type='static'
        />
      ))}
    </div>
  )
}

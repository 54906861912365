import React from 'react'
import Typography from '@material-ui/core/Typography'
import DeckGL from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { StaticMap } from 'react-map-gl'
import { HeatmapLayer } from '@deck.gl/aggregation-layers'
import { EditableMap } from './MapView'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
const defaultValue = { type: 'FeatureCollection', features: [] }

export default function HeatMapView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const value = data.value || defaultValue
  const layer = new GeoJsonLayer({
    data: value,
  })

  /*
  const computeAvg = (elem) => {
    if (data.value.length <= 0) {
      return 0
    }
    let sum = 0
    for (var i = 0; i < data.value.length; i++) {
      sum += data.value[i][elem]
    }
    return sum / data.value.length
  }

  const INITIAL_VIEW_STATE = {
    longitude: computeAvg(0),
    latitude: computeAvg(1),
    zoom: 9,
    maxZoom: 16,
    pitch: 0,
    bearing: 0,
  }
  */
  const INITIAL_VIEW_STATE = {
    longitude: -112.0372,
    latitude: 46.608058,
    zoom: 13,
    pitch: 0,
    bearing: 0,
  }

  const label = name || id

  switch (type) {
    default:
    case 'static': {
      return (
        <div>
          <Typography>{label}</Typography>
          <div style={{ position: 'relative', height: '300px' }}>
            <DeckGL
              initialViewState={INITIAL_VIEW_STATE}
              controller={true}
              layers={[layer]}
            >
              <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
            </DeckGL>
          </div>
        </div>
      )
    }
    case 'input': {
      return (
        <EditableMap
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
          data={data}
          // label={label}
          setData={setData}
        />
      )
    }
    case 'output': {
      return (
        <HeatMap
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
          data={data}
          // label={label}
        />
      )
    }
  }
}

const defaultHeapMapValue = []
export function HeatMap({
  initialViewState,
  data,
  controller,
  label,
  intensity = 1,
  threshold = 0.03,
  radiusPixels = 30,
}) {
  const value = data.value || defaultHeapMapValue

  const layer = new HeatmapLayer({
    data: value,
    // id: 'heatmp-layer',
    pickable: false,
    getPosition: d => [d[0], d[1]],
    getWeight: d => d[2],
    radiusPixels,
    intensity,
    threshold,
  })

  return (
    <div style={{ position: 'relative', height: '300px' }}>
      <DeckGL
        initialViewState={initialViewState}
        controller={true}
        layers={[layer]}
      >
        <StaticMap
          reuseMaps
          preventStyleDiffing={true}
          mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        />
      </DeckGL>
    </div>
  )
}

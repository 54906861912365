import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import SignInButton from '../components/auth/SignInButton'
import { getIsUserAuthenticated } from '../components/auth/authSlice'

import {
  ReactComponent as CrossComputeLogo,
} from '../images/CrossCompute-Logo-LargeFormat-20190613.svg'

import {
  ACKNOWLEDGEMENTS_PATH,
  ROOT_PATH,
} from '../constants'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.crosscompute.colors.gray,
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  paper: {
    width: '450px',
    padding: theme.spacing(8, 3, 5, 3),
  },
  list: {
    'listStyleType': 'none',
    'margin': 0,
    'padding': 0,
    'marginBottom': theme.spacing(1),
    '& li': {
      display: 'inline-block',
      padding: theme.spacing(0, 1),
    },
  },
}))

export default function SignInPage(props) {
  const classes = useStyles()
  const isUserAuthenticated = useSelector(getIsUserAuthenticated)

  if ( isUserAuthenticated ) {
    return <Redirect to={ROOT_PATH} />
  }

  return (
    <Box display='flex' className={classes.root}>
      <Paper elevation={1} className={classes.paper}>
        <div className={classes.logoContainer}>
          <CrossComputeLogo width={100}/>
        </div>
        <div>
          <Typography variant='h4' component='h1' align='center'>CrossCompute</Typography>
          <Typography variant='body1' component='h2' align='center'>
            Optimize Your Work
          </Typography>
        </div>
        <Box p={5}>
          <SignInButton />
        </Box>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <ul className={classes.list}>
            <li>
              <Typography color='textSecondary' variant='caption' component='p'>
                <Link href={ACKNOWLEDGEMENTS_PATH} color='inherit' target='_blank'>
                  Acknowledgements
                </Link>
              </Typography>
            </li>
          </ul>
          <Typography align='center' color='textSecondary'>CrossCompute &copy; 2021</Typography>
        </div>
      </Paper>
    </Box>
  )
}

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
// import IconButton from '@material-ui/core/IconButton'
// import ClipboardIcon from '@material-ui/icons/Assignment'

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    minHeight: '50vh',
    minWidth: '80vh',
  },
}))

export default function ResourceShareDialog({
  projectId,
  resultId,
  templateId,
}) {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [resourceTypeName, setResourceTypeName] = useState('form')

  function handleTabChange(event, newValue) {
    setResourceTypeName(newValue)
  }

  const templatePath = templateId ? '/templates/' + templateId : ''
  // const projectUrl
  const formUrl =
    window.location.origin + '/forms/' + resultId +
    templatePath + '#' + projectId
  const resultUrl =
    window.location.origin + '/results/' + resultId +
    templatePath

  return <>
    <div>
      <Button
        onClick={() => setIsOpen(true)}
      >
        Share
      </Button>
    </div>

    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <DialogContent>
        <Tabs
          value={resourceTypeName}
          indicatorColor='primary'
          textColor='primary'
          onChange={handleTabChange}
        >
          <Tab label='Form' value='form' />
          {/* <Tab label='Result' value='result' disabled /> */}
        </Tabs>
        <div
          hidden={resourceTypeName !== 'form'}
        >
          <Typography component='p'>
            Share this form URL to let users submit data using the selected result input and template.
          </Typography>

          {/*
          <IconButton onClick={() => {}}>
            <ClipboardIcon />
          </IconButton>
          */}

          <Typography component='p'>
            <Link
              id='formUrl'
              href={ formUrl }
              target='_blank'
            >
              { formUrl }
            </Link>
          </Typography>
        </div>

        <div
          hidden={resourceTypeName !== 'result'}
        >
          <Typography component='p'>
            Share this result URL to let users view the selected result output and template.
          </Typography>

          <Typography component='p'>
            <Link
              id='resultUrl'
              href={ resultUrl }
              target='_blank'
            >
              { resultUrl }
            </Link>
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  </>
}

import React from 'react'
// import BarchartView from './BarchartView'
import MarkdownView from './MarkdownView'
import NumberView from './NumberView'
import TableView from './TableView'
import MapView from './MapView'
import ImageView from './ImageView'
import HeatmapView from './HeatmapView'
import TextView from './TextView'
import ElectricityNetworkView from './ElectricityNetworkView'

export default function Block({
  id,
  name,
  type,
  view,
  data,
  setData,
  onSaveClick,
  onCancelClick,
  fullHeight = false,
  className = '',
}) {
  /*
  console.log(data)
  if (data === undefined) {
    return (
      <MarkdownView id={id} type='static' data={'{' + name + '}'} />
    )
  }
  */

  let Component = null
  switch (view) {
    case 'number': {
      /*
      return (
        <NumberView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <NumberView id={id} name={name} type={type} data={data} setData={setData} />)
      break
    }
    case 'text': {
      /*
      return (
        <TextView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <TextView id={id} name={name} type={type} data={data} setData={setData} className={className} />)
      break
    }
    case 'table': {
      /*
      return (
        <TableView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <TableView id={id} name={name} type={type} data={data} setData={setData} fullHeight={fullHeight} />)
      break
    }
    case 'map': {
      /*
      return (
        <MapView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <MapView
          id={id}
          name={name}
          type={type}
          data={data}
          setData={setData}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
          fullHeight={fullHeight}
        />)
      break
    }
    case 'image': {
      /*
      return (
        <ImageView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <ImageView id={id} name={name} type={type} data={data} setData={setData} />)
      break
    }
    case 'heatmap': {
      /*
      return (
        <HeatmapView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <HeatmapView id={id} name={name} type={type} data={data} setData={setData} fullHeight={fullHeight} />)
      break
    }
    case 'markdown': {
      /*
      return (
        <MarkdownView id={id} name={name} type={type} data={data} setData={setData} />
      )
      */
      Component = (
        <MarkdownView id={id} name={name} type={type} data={data} setData={setData} />)
      break
    }
    /*
    case 'barchart': {
      return (
        <BarchartView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    */
    case 'electricity-network': {
      Component =(
        <ElectricityNetworkView id={id} name={name} type={type} data={data} setData={setData} />)
      break
    }
    default: {
      return null
    }
  }
  return Component
}

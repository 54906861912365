// TODO: Rewrite
import React from 'react'
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
// TODO: Use NativeSelect
import Select from '@material-ui/core/Select'

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
})

const useSelectStyles = makeStyles(theme => ({
  select: {
    background: '#2b2d5c',
  },
  selectInputLabel: {
    'textAlign': 'center',
    'fontSize': theme.spacing(2),
    'left': '50%',
    'transform': 'translate(-50%, -50%)',
    'color': 'black',
    'fontWeight': 'bold',
    'width': '100%',
    '&:focus': {
      color: 'black !important',
    },
  },
}))

export default function MySelect({
  label,
  options = [],
  value,
  emptyOptionLabel = '',
  onChange,
}) {
  const classes = useSelectStyles()
  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        htmlFor={`${label}-native-select`}
        className={classes.selectInputLabel}
      >
        {label}
      </InputLabel>
      <ThemeProvider theme={theme}>
        <Select
          native
          value={value || ''}
          inputProps={{
            name: 'tools',
            id: `${label}-native-select`,
          }}
          className={classes.select}
          onChange={onChange}
        >
          { emptyOptionLabel &&
            <option aria-label='none' value=''>{emptyOptionLabel}</option>
          }
          {options.map(option => {
            return <option key={option.id} value={option.value}>{option.name}</option>
          })}
        </Select>
      </ThemeProvider>
    </FormControl>
  )
}

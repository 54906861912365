import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants'

export const formSlice = createSlice({
  name: 'form',
  initialState: {},
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

export const { set: setForm } = formSlice.actions

export const getForm = state => {
  return state.form
}

export default formSlice.reducer

import React from 'react'
import { useDropzone } from 'react-dropzone'
import IconButton from '@material-ui/core/IconButton'

export default function UploadButton({
  children,
  onDrop,
}) {
  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true })
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton aria-label='Upload File'>
        {children}
      </IconButton>
    </div>
  )
}

import { apiRequest } from './index'
import { removeProject, setProjects } from '../components/projects/projectsSlice'

export const fetchProjects = () => async dispatch => {
  return apiRequest({
    path: '/projects.json',
  }).then(res => {
    /*
    const newProjects = res.map(project => {
      const newProject = project
      const datasetIds = project.datasets.map(d => d.id)
      const toolIds = project.tools.map(t => t.id)
      const resultIds = project.results.map(r => r.id)
      return { ...newProject, datasetIds, toolIds, resultIds }
    })
    */
    dispatch(setProjects(res))
  })
}

export const createProject = (name, toolIds = []) => async dispatch => {
  return apiRequest({
    path: '/projects.json',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, toolIds }),
    },
  })
}

export const deleteProject = (id) => async dispatch => {
  return apiRequest({
    path: '/projects.json',
    options: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectId: id }),
    },
  })
    .then(() => dispatch(removeProject(id)))
}


import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { getAuth } from './authSlice'
import DefaultTemplate from '../../templates/DefaultTemplate'
import LoadingPage from '../../pages/LoadingPage'
import { SIGN_IN_PATH } from '../../constants'

// TODO: Remove excludeNavbar prop because it does not belong here
export default function ProtectedRoute({ component: Component, excludeNavbar = false, ...rest }) {
  const auth = useSelector(getAuth)

  if (!auth.urls.leave) {
    // auth data note laded
    return <LoadingPage />
  }

  if (!auth.user) {
    return <Redirect to={{ pathname: SIGN_IN_PATH, state: rest.location }} />
  }

  return (
    <Route {...rest} render={(props) => {
      if (excludeNavbar) return <Component {...rest} {...props} />
      return (
        <DefaultTemplate>
          <Component {...props} />
        </DefaultTemplate>)
    }}/>
  )
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import CreateProjectWizard from '../components/CreateProjectWizard'
import { useDispatch } from 'react-redux'
import { createProject } from '../api/projects'

export default function ProjectCreatePage() {
  const dispatch = useDispatch()
  const history = useHistory()

  function onFinish(e, data) {
    dispatch(createProject(data.name, data.toolIds))
      .then((res) => history.push(`/projects/${res.id}`))
  }

  return (
    <CreateProjectWizard onFinish={onFinish} />
  )
}

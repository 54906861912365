import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ProjectsIcon from '@material-ui/icons/FolderOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import SignOutIcon from '@material-ui/icons/ExitToAppOutlined'
import SvgIcon from '@material-ui/core/SvgIcon'
import NavbarButton from '../components/NavbarButton'
import UserAvatar from '../components/auth/UserAvatar'
import { getAuth } from '../components/auth/authSlice'
import {
  // BOARDS_PATH,
  NAVIGATION_BAR_WIDTH,
  PROJECTS_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
} from '../constants'

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    width: NAVIGATION_BAR_WIDTH,
  },
  drawerPaper: {
    alignItems: 'center',
    width: NAVIGATION_BAR_WIDTH,
    overflow: 'hidden',
  },
}))

export default function NavbarSection(props) {
  const classes = useStyles()
  const { urls } = useSelector(getAuth)
  const navButtonData = {
    projects: {
      icon: ProjectsIcon,
      url: PROJECTS_PATH,
    },
    settings: {
      icon: SettingsIcon,
      url: SETTINGS_PATH,
    },
  }

  return (
    <Drawer
      anchor='left'
      variant='permanent'
      classes={{ paper: classes.drawerPaper }}
      className={classes.drawer}
    >
      <List>
        <NavbarButton
          to={ROOT_PATH}
          Icon={<SvgIcon component={UserAvatar} viewBox='0 0 49 49' fontSize='large'/>}
        />
        {[
          'Projects',
          'Settings',
        ].map((text, index) => {
          const buttonDataKey = text.toLowerCase()
          const { url, icon: IconComponent } = navButtonData[buttonDataKey]
          return (
            <NavbarButton
              key={text}
              text={text}
              to={url}
              Icon={<IconComponent fontSize='large'/>}
            />
          )
        })}
        {/* TODO: Render different boards if not authenticated */}
        <NavbarButton
          external
          text='Sign Out'
          to={urls.leave + '?targetUrl=' + window.location.origin}
          Icon={<SignOutIcon fontSize='large'/>}
        />
      </List>
    </Drawer>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    color: 'black',
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold',
  },
}))

export default function HeaderSection({ title, children }) {
  const classes = useStyles()
  return (
    <AppBar position='static' component='div' className={classes.appBar}>
      <Toolbar disableGutters className={classes.toolbar}>
        { title &&
          <Typography variant='h5' className={classes.title}>
            {title}
          </Typography>
        }
        {children}
      </Toolbar>
    </AppBar>
  )
}

import { apiRequest } from './index'
import { setTools } from '../components/tools/toolsSlice'

export const fetchTools = () => async dispatch => {
  return apiRequest({
    path: '/tools.json',
  })
    .then(res => dispatch(setTools(res)))
}

export const createTool = (newTool) => async dispatch => apiRequest({
  path: '/tools.json',
  options: {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(newTool),
  },
})

import { produce } from 'immer'
import { apiRequest } from './index'
import { setResults } from '../components/results/resultsSlice'
import {createResult as createResultActionCreator, setProject} from '../components/project/projectSlice'
import {setResult} from "../components/result/resultSlice";

export const fetchResults = () => async dispatch => {
  return apiRequest({
    path: '/results.json',
  }).then(res => setResults(res))
}

export const fetchResult = (id, data) => async dispatch => {
  return apiRequest({
    path: `/results/${id}.json`,
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  }).then(res => {
    dispatch(setResult(res))
  })
}

export const createResult = ({
  projectId,
  toolName = 'test',
  toolId,
  toolVersionId,
  formId,
  inputVariables,
  outputVariables=[],
}) => async dispatch => {
  const variables = inputVariables

  const data = {
    name: toolName,
    project: { 'id': projectId },
    tool: {
      id: toolId,
      version: {
        id: toolVersionId,
      },
    },
    formId: formId,
    input: {
      variables,
    },
  }

  const fileSummaryByDatasetId = variables.reduce((dict, block) => {
    // const { id, data } = block
    const { dataset, file: fileData } = block.data
    if (dataset && dataset.id && fileData && fileData.id) {
      const datasetId = dataset.id
      const datasetVersionId = dataset.version.id
      dict[`${datasetId}-${datasetVersionId}`] = fileData
    }
    return dict
  }, {})

  return apiRequest({
    path: '/results.json',
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  })
    .then(result => {
      const resultWithSummary = produce(result, draft => {
        const { input } = draft
        const inputVariables = input.variables
        inputVariables.forEach(variable => {
          const { data } = variable
          const { dataset } = data
          if (!dataset) return

          const datasetId = dataset.id
          const datasetVersionId = dataset.version.id
          const id = `${datasetId}-${datasetVersionId}`
          if ( id in fileSummaryByDatasetId) {
            // insert summary data
            const { summary, id: fileId } = fileSummaryByDatasetId[id]
            if ( summary ) {
              variable.data.file = { id: fileId, summary }
            }
          }
        })
        // output is not received from backend
        draft.output = draft.output ? draft.output : { variables: outputVariables }
      })
      dispatch(createResultActionCreator(resultWithSummary))
      return result.id
    })
}


export const updateResultAccessPolicy = (id, data) => async dispatch => {
    return apiRequest({
      path: `/policy/results/${id}.json`,
      options: {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    }).then(res => {
      dispatch(setResults(res))
    }).catch(e => {
      console.log(e)
      alert(e)
    })
}

export const deletePolicy = (id) => async dispatch => {
    return apiRequest({
      path: `/policy/${id}.json`,
      options: {
        method: 'delete',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    }).then(res => {
      dispatch(setResults(res))
    })
}
import { apiRequest } from './index'

export const addFile = ({ kind, view, type, name, size, projects }) => async dispatch => {
  // TODO: Use await
  return apiRequest({
    path: '/files.json',
    options: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ kind, view, type, name, size, projects }),
    },
  }).then(res => {
    return res
  })
}

export const getSignedUrl = fileId => async dispatch => {
  return apiRequest({
    path: `/files/${fileId}.json`,
  })
}

export const changeFileStatus = ({ fileId }) => async dispatch => {
  return apiRequest({
    path: `/files/${fileId}.json`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  })
}

import React from 'react'
import Markdown from 'markdown-to-jsx'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function MarkdownView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const defaultValue = ''
  const value = data.value || defaultValue

  switch (type) {
    default:
    case 'static': {
      return (
        <div style={{ width: '100%' }}>
          <Markdown>
            {data + '\n'}
          </Markdown>
        </div>

      )
    }
    case 'input': {
      return (
        <div style={{ width: '100%', height: '100%' }}>
          <TextField
            fullWidth
            multiline
            value={value}
            variant='filled'
            size='small'
            onChange={(e) => setData({ value: e.target.value })}
          />
        </div>
      )
    }
    case 'output': {
      // TODO: Make more universal
      return (
        <Typography>
          <Markdown children={value} />
        </Typography>
      )
    }
  }
}

import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

export default function Stepper({
  children = [],
  onNext = () => {},
  onBack = () => {},
  onFinish = () => {},
  HeaderComponent,
}) {
  const [activeStepIndex, setActiveStepIndex] = useState(0)
  const totalSteps = children.length
  const lastStepIndex = totalSteps - 1

  function handleNext(e) {
    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex + 1)
    onNext(e)
  }

  function handleBack(e) {
    setActiveStepIndex(prevActiveStepIndex => prevActiveStepIndex - 1)
    onBack(e)
  }

  function handleFinish(e) {
    // console.log('finish')
    onFinish(e)
  }

  const ChildComponent = totalSteps > 1 ? children[activeStepIndex] : children

  return (
    <Box display='flex' flexDirection='column' style={{ width: '100%', height: '100%' }}>
      { HeaderComponent }
      <Box
        flexGrow={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        style={{ height: '100%' }}
      >
        { ChildComponent }
      </Box>
      <Box display='flex' style={{ height: '20%', margin: '0 auto' }}>
        {
          activeStepIndex > 0 &&
            <div>
              <Button color='inherit' disableRipple onClick={handleBack}>Back</Button>
            </div>
        }
        {
          <div>
            {activeStepIndex < lastStepIndex
              ? <Button color='inherit' disableRipple onClick={handleNext}>Next</Button>
              : <Button color='inherit' disableRipple onClick={handleFinish}>Finish</Button>
            }
          </div>
        }
      </Box>
    </Box>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const datasetsSlice = createSlice({
  name: 'datasets',
  initialState: [],
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
    setDatasetValue(state, action) {
      const { datasetId, value } = action.payload
      const dataset = state.find(dataset => dataset.id === datasetId)
      if (!dataset) return
      dataset.data.value = value
    },
  },
})

export const { set: setDatasets, setDatasetValue } = datasetsSlice.actions

export const getDatasets = state => state.datasets

export default datasetsSlice.reducer

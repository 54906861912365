// TODO: Rewrite to support input, output, log, debug blocks
// TODO: !!! Fetching project in the form is very bad and is a security leak
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { produce } from 'immer'
import { useDispatch, useSelector } from 'react-redux'
// import { Link as RouterLink } from 'react-router-dom'
import {
  makeStyles,
} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
// import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Grid from '@material-ui/core/Grid'
// import Link from '@material-ui/core/Link'
// import NavigateNextIcon from '@material-ui/icons/NavigateNext'
// import ToggleButton from '@material-ui/lab/ToggleButton'
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'

import {
  getProject,
  // updateProjectResult,
  // setProjectResultFileIdSummary,
} from '../components/project/projectSlice'
import Blocks from '../components/Blocks'
import ButtonWithDialog from '../components/ButtonWithDialog'
// import MySelect from '../components/MySelect'

import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'

import {
  createResult,
  // fetchResult,
} from '../api/results'
import {
  fetchProject,
  // fetchProjectToolVersion,
} from '../api/project'

// import { SERVER_URL } from '../constants'

import {
  getRandomString,
} from '../macros'
// import Button from '@material-ui/core/Button'
// import PublishForm from '../components/PublishForm'
// import Dialog from '@material-ui/core/Dialog'
// import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
// import DialogContentText from '@material-ui/core/DialogContentText'
// import DialogTitle from '@material-ui/core/DialogTitle'
// import TextField from '@material-ui/core/TextField'
import { getResult } from '../components/result/resultSlice'
import { fetchForm } from '../api/forms'
import { getForm } from '../components/form/formSlice'
import { useEchoesServer } from '../hooks/echoes'

const useStyles = makeStyles(theme => ({
  runButton: {
    marginRight: theme.spacing(1),
  },
  toggleButtonRoot: {
    color: 'black',
  },
  selectedToggleButton: {
    color: 'white !important',
    background: 'black !important',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}))

export function useLoadForm(formId, fetcher=fetchForm) {
  const dispatch = useDispatch()
  const form = useSelector(getForm)

  useEffect(() => {
    dispatch(fetcher(formId))
    // firstRender.current = false
  }, [dispatch, formId])

  return [form]
}

export function useLoadProject(projectId) {
  // const firstRender = useRef(true)
  const dispatch = useDispatch()
  const project = useSelector(getProject)
  // const isLoading = firstRender.current || project.id !== projectId
  const isLoading = project.id !== projectId
  const token = project.token

  useEffect(() => {
    dispatch(fetchProject(projectId))
    // firstRender.current = false
  }, [dispatch, projectId])

  return [project, token, isLoading]
}

function useGetResultVariableDataById(result) {
  const [editableVariableDataById, setEditableVariableDataById] = useState({})
  const resultVariableDataById = useRef()
  const isEditing = useRef(false)

  useEffect(() => {
    if (!result || isEditing.current) return
    const inputVariableById = getVariableDataById(result.input, 'input')
    const variablesById = { ...inputVariableById }
    resultVariableDataById.current = variablesById
    setEditableVariableDataById(variablesById)
    isEditing.current = false
  }, [result])

  function getVariableDataById(obj, blockType) {
    const o = obj || {}
    const variables = o.variables || []
    return variables.reduce((dict, variable) => {
      const { id, data } = variable
      const key = `${id}-${blockType}`
      dict[key] = { ...data, misc: { blockType, key: getRandomString(16), id } }
      return dict
    }, {})
  }

  function setEditableData(fn, updateIsEditing=true) {
    if (updateIsEditing) {
      isEditing.current = true
    }
    return setEditableVariableDataById(produce(fn))
  }

  function resetResultValues() {
    isEditing.current = false
    setEditableVariableDataById(resultVariableDataById.current)
  }

  const editing = {
    value: isEditing.current,
    resetResultValues,
    ref: isEditing,
  }

  return [editableVariableDataById, setEditableData, editing]
}

function getFirstElement(arr) {
  if (arr.length === 0)
    return
  return arr[0]
}

function getSelectedTool(tools, selectedToolId) {
  if (!tools) return
  if (!selectedToolId)
    return getFirstElement(tools)
  return tools.find(tool => tool.id === selectedToolId) || getFirstElement(tools)
}

function getSelectedVersion(tool) {
  if (!tool) return
  const version = tool.version
  return version
}

function getSelectedTemplate(tool, selectedTemplateId, templateType) {
  if (!tool) return
  const templates = tool[templateType] && tool[templateType].templates
  if (!templates) return
  if (!selectedTemplateId) return
  return templates.find(template => template.id === selectedTemplateId)
}

function getSelectedResult(results, resultId, tool, version) {
  if (!results || !tool || !version) return
  const toolId = tool.id
  const versionId = version.id
  const toolResults = results
    .filter(result => result.tool.id === toolId && result.tool.version.id === versionId)
  const result = toolResults.find(result => result.id === resultId)
  return result || getFirstElement(toolResults)
}

function makeOption(obj) {
  return { id: obj.id, value: obj.id, name: obj.name }
}

function getObjId(obj) {
  return obj ? obj.id : null
}

function useSelectedOptionByName(project, isLoading, blockMode, resultId, templateId) {
  const [selectedOptionIdByName, setSelectedOptionIdByName] = useState({ result: resultId, template: templateId })

  const selectedOptionsByName = useMemo(() => {
    if (isLoading) return { tool: {}, version: {}, template: {}, result: {} }
    const selectedToolId = selectedOptionIdByName.tool
    // const selectedVersionId = selectedOptionIdByName.version
    const selectedTemplateId = selectedOptionIdByName.template
    const selectedResultId = selectedOptionIdByName.result
    const tool = getSelectedTool(project.tools, selectedToolId)
    const version = getSelectedVersion(tool)
    const template = getSelectedTemplate(tool, selectedTemplateId, 'input')
    const result = getSelectedResult(project.results, selectedResultId, tool, version)
    const newSelectedOptionByName = {
      tool: { id: getObjId(tool), selected: tool },
      version: { id: getObjId(version), selected: version },
      template: { id: getObjId(template), selected: template },
      result: { id: getObjId(result), selected: result },
    }
    return newSelectedOptionByName
  }, [isLoading, project, blockMode, selectedOptionIdByName])

  function updateSelectedIdByName(idByName = {}) {
    setSelectedOptionIdByName(prevState => ({ ...prevState, ...idByName }))
  }

  const selectedToolId = selectedOptionsByName.tool.id
  const selectedVersionId = selectedOptionsByName.version.id
  // const selectedTemplateId = selectedOptionsByName.template.id
  // const selectedResultId = selectedOptionsByName.result.id

  const resultOptions = useMemo(() => {
    if (isLoading) return []
    if (!project.results) return []
    const results = project.results
      .filter(result => result.id === resultId)
    const resultOptions = results.map(result => makeOption(result))
    return resultOptions
  }, [isLoading, selectedToolId, selectedVersionId, project])

  const versionOptions = useMemo(() => {
    if (isLoading) return []
    if (!project.tools) return []
    const tool = project.tools.find(tool => tool.id === selectedToolId)
    if (!tool) return []
    const versionOptions = tool.versions.map(version => makeOption(version))
    return versionOptions
  }, [isLoading, selectedToolId, project.tools])

  const templateOptions = useMemo(() => {
    if (isLoading) return []
    const templateType = 'input'
    if (!project.tools) return []
    const selectedTool = project.tools.find(tool => tool.id === selectedToolId)
    if (!selectedTool) return []
    const templates = selectedTool[templateType].templates || []
    return templates.map(template => makeOption(template))
  }, [isLoading, selectedToolId, project.tools])

  const optionsByName = { result: resultOptions, version: versionOptions, template: templateOptions }

  return [selectedOptionsByName, optionsByName, updateSelectedIdByName]
}

export default function FormPage({
  match: {
    params: {
      formId,
      templateId,
    },
  },
}) {
/*
  const [requestedForm] = useLoadForm(formId)
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState('')
  const [project, token, isLoading] = useLoadProject(requestedForm.project ? requestedForm.project.id : '')

  useEffect(() => {
    setOpen(requestedForm.password)
  }, [requestedForm])

  const manageAccesss = () => {
    if (password) {
      dispatch(fetchForm(formId, { password })).then(() => window.location.reload())
    }
  }

  const handleClose = () => {
    window.location.href = '/'
  }
*/

  const classes = useStyles()
  const dispatch = useDispatch()
  const projectId = window.location.hash.slice(1)
  const [project, token, isLoading] = useLoadProject(projectId)
  const [submitted, setSubmitted] = useState(false)
  const resultId = formId
  const [selectedOptionByName, optionsByName, setSelectedOptionIdByName] = useSelectedOptionByName(project, isLoading, 'input', resultId, templateId)

  const { result: resultOptions, version: versionOptions, template: templateOptions } = optionsByName
  const { selected: selectedTool, id: selectedToolId } = selectedOptionByName.tool
  const { selected: selectedVersion, id: selectedVersionId } = selectedOptionByName.version
  const { selected: selectedTemplate, id: selectedTemplateId } = selectedOptionByName.template
  const { selected: selectedResult, id: selectedResultId } = selectedOptionByName.result

  const [resultDataById, setResultDataById, editing] = useGetResultVariableDataById(selectedResult)
  const {
    resetResultValues, ref: isEditingResultRef,
  } = editing

  // const newResultName = `${selectedResult && selectedResult.name} > New`
  useEchoesServer(token, setResultDataById)

  function updateSelectedOptionIdByName(valueById) {
    isEditingResultRef.current = false
    setSelectedOptionIdByName(valueById)
  }

/*
  if (!requestedForm.id )  return <></>

  if (requestedForm.password) {
    return <MainSection>
      <Grid container className={classes.marginTop}>
        <Grid item xs={12}>
          <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
            <DialogTitle id='form-dialog-title'>Introduce the password</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This result page is protected, introduce the correct password to access the resources.
              </DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Password'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={manageAccesss} color='primary'>
                Access
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </MainSection>
  }
*/

  if (isLoading) return null

  return (
    <MainSection>
      <HeaderSection>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexGrow={1}
        >
          {/*
          <Box display='flex' alignItems='flex-end'>
            <Breadcrumbs
              component='div'
              separator={<NavigateNextIcon />}
              aria-label='breadcrumb'
              color='inherit'
            >
              <Link color='inherit' variant='h6' to='/projects' component={RouterLink}>
                Projects
              </Link>
              <Typography variant='h6'>{project.name}</Typography>
            </Breadcrumbs>
          </Box>
          */}
          <Box display='flex'>
            <div className={classes.runButton}>
              {/* Remove Name Your Result */}
              <ButtonWithDialog
                isEnabled={!submitted}
                text='Submit'
                handleOnRun={(toolName) => {
                  const inputVariables = Object.keys(resultDataById)
                    .filter(id => resultDataById[id].misc.blockType === 'input' )
                    .map(id=> {
                      const { misc, ...data } = resultDataById[id]
                      return { data, id: misc.id }
                    })
                  const outputVariables = Object.keys(resultDataById)
                    .filter(id => resultDataById[id].misc.blockType === 'output')
                    .map(id => {
                      const { misc } = resultDataById[id]
                      return { data:  { value: undefined }, id: misc.id }
                    })
                  dispatch(createResult({
                    toolName,
                    projectId: project.id,
                    toolId: selectedToolId,
                    toolVersionId: selectedVersionId,
                    formId,
                    inputVariables,
                    outputVariables,
                  })).then(id => {
                    updateSelectedOptionIdByName({ result: id })
                    setSubmitted(true)
                    // setBlockMode(OUTPUT_BLOCK_MODE)
                  }).catch((e) => {
                    alert('Something went wrong, please reload the page.')
                  })

                }}
              />
            </div>
          </Box>
        </Box>
      </HeaderSection>

      { submitted ?
        <Grid container className={classes.marginTop}>
          <Grid item xs={12}>
            <Typography align='center' component='h3'>Form submitted successfully!</Typography>
          </Grid>
        </Grid> : <>
          {/*
          <Grid container spacing={2} className={classes.marginTop}>
            <Grid item xs={12} sm={3}>
              <Typography component='p'>Tool: {selectedTool ? selectedTool.name : ''}</Typography>
              <Typography component='p'>Version: {selectedVersion ? selectedVersion.name : ''}</Typography>
            </Grid>
          </Grid>
          */}
          <Grid container className={classes.marginTop}>
            <Grid item xs={12}>
              { !isLoading &&
                <Blocks
                  blockType={'input'}
                  tool={selectedTool}
                  version={selectedVersion}
                  template={selectedTemplate}
                  result={selectedResult}
                  resultDataById={resultDataById}
                  setResultDataById={setResultDataById}
                  projectId={project.id}
                />
              }
            </Grid>
          </Grid>
        </>}
    </MainSection>
  )
}

// TODO: Reconsider this component
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

export default function ButtonWithDialog({
  isEnabled,
  label = '',
  text,
  handleOnRun = () => {},
}) {
  const [name, setName] = useState(label)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setName(label)
  }, [label])

  function closeDialog() {
    setIsOpen(false)
  }

  function handleOnRunClick(e) {
    handleOnRun(name)
    setIsOpen(false)
  }

  return (
    <div>
      <Button
        disabled={!isEnabled}
        color='primary'
        variant='contained'
        onClick={() => setIsOpen(true)}
      >
        {text ? text : 'Run'}
      </Button>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='create-dialog-title'
      >
        <DialogTitle id='create-dialog-title'>
          <Box display='flex'>
            <Box flexGrow={1} alignSelf='flex-end'>
              Name your result
            </Box>
            <Box alignSelf='flex-start'>
              <IconButton
                onClick={closeDialog}
                style={{ padding: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Name'
            defaultValue={name}
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnRunClick}
          >
            {text ? text : 'Run'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

import React from 'react'
import { SummaryMap } from './MapView'

export default function ElectricityNetworkView({
  id,
  name,
  type,
  data,
  setData,
}) {
  // console.log(id, name, type, data)
  switch (type) {
    default:
    case 'summary': {
      let d
      if (data.value) {
        d = {value: data.value.assets.assetsGeoJson}
      } else if (data.file) {
        d = data
      } else {
        d = {value: {type: 'FeatureCollection', features: []}}
      }
      return (
        <SummaryMap id={id} data={d} />
      )
    }
    case 'static': {
      return (
        <div>static</div>
      )
    }
    case 'input': {
      return (
        <div>input</div>
      )
    }
    case 'output': {
      return (
        <div>output</div>
      )
    }
  }
}

import React, { useEffect, useState } from 'react'

import { SERVER_URL } from '../constants'

export default function FileImageScreenPage({
  match: { params: { datasetId } },
}) {
  const [url, setUrl] = useState()
  const [
    datasetVersionId,
    blockKey,
    projectId,
  ] = window.location.hash.substring(1).split('-')

  useEffect(() => {
    async function fetchData() {
      let url = SERVER_URL + '/datasets/' + datasetId
      if (datasetVersionId) {
        url += '/versions/' + datasetVersionId
      }
      url += '.json'

      const response = await fetch(url, {
        credentials: 'include',
        method: 'GET',
      })
      const responseJson = await response.json()
      const fileUrl = responseJson.file.url
      setUrl(fileUrl)
    }
    fetchData()
  }, [datasetId, datasetVersionId])

  if (!url) return null

  return (
    url && <img src={url} alt='' style={{ maxWidth: '100%' }} />
  )
}

import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function NumberView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const label = name || id
  const value = data ? data.value : ''
  switch (type) {
    default:
    case 'static': {
      return (
        <Typography
        >
          {value}
        </Typography>
      )
    }
    case 'input': {
      return (
        <TextField
          label={label}
          type='number'
          value={value}
          variant='filled'
          fullWidth
          onChange={event => setData({ value: event.target.value })}
        />
      )
    }
    case 'output': {
      return (
        <TextField
          className='output'
          label={label}
          type='number'
          value={value}
          variant='filled'
          fullWidth
          InputLabelProps={{
            className: 'output',
          }}
          InputProps={{
            className: 'output',
            readOnly: true,
          }}
        />
      )
    }
  }
}

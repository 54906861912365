import React from 'react'
import BarchartView from './BarchartView'
import MarkdownView from './MarkdownView'
import NumberView from './NumberView'
import TableView from './TableView'
import MapView from './MapView'
import ImageView from './ImageView'
import HeatmapView from './HeatmapView'
import TextView from './TextView'

export default function Block({
  id,
  name,
  type,
  view,
  data,
  setData,
}) {
  switch (view) {
    case 'number': {
      return (
        <NumberView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'text': {
      return (
        <TextView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'table': {
      return (
        <TableView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'map': {
      return (
        <MapView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'image': {
      return (
        <ImageView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'heatmap': {
      return (
        <HeatmapView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'markdown': {
      return (
        <MarkdownView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    case 'barchart': {
      return (
        <BarchartView id={id} name={name} type={type} data={data} setData={setData} />
      )
    }
    default: {
      return null
    }
  }
}

// TODO: Rewrite to support input, output, log, debug blocks
// TODO: Consider removing this file
// TODO: Check why value is null
import React, { useMemo, useState, useEffect, useRef, useCallback, memo } from 'react'
import { VariableSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import _ from 'lodash'

import Block from './Block'
// import DatasetSelect from './DatasetSelect'
// import BlockUploadButton from './BlockUploadButton'
// import { fetchProjectDatasetValue } from '../api/project'
import ProjectPageBlockCard from './ProjectPageBlockCard'
import {
  getRandomString,
} from '../macros'

function getBlockTypeByView(view, blockType) {
  let newBlockType = blockType
  try {
    const blockTypeForView = {
      'map': {
        'output': 'summary',
        'input': 'summary',
      },
      'table': {
        'output': 'summary',
        'input': 'summary',
      },
      'electricity-network': {
        'output': 'summary',
        'input': 'summary',
      },
    }
    newBlockType = blockTypeForView[view][blockType]
  } catch(error) {}
  return newBlockType
}

function getToolVariableDefinition(tool, variableType) {
  return tool[variableType] ? tool[variableType].variables : []
}

function getResultDebugVariables(result) {
  return result.debug ? result.debug.variables : []
}

function mergeVariableDefinitionsWithValues(definitions, resultDataById, blockType) {
  return definitions.map(definition => {
    const { id } = definition
    const key = `${id}-${blockType}`
    const data = resultDataById[key] || {}
    return {
      ...definition,
      data,
    }
  }) 
}

function getVariableDefinitionsWithValue(tool, resultDataById) {
  if (!tool) return { input: [], output: [] }
  const variableInputDefinitions = getToolVariableDefinition(tool, 'input')
  const variableOutputDefinitions = getToolVariableDefinition(tool, 'output')
  const inputWithValues = mergeVariableDefinitionsWithValues(variableInputDefinitions, resultDataById, 'input')
  const outputWithValues = mergeVariableDefinitionsWithValues(variableOutputDefinitions, resultDataById, 'output')

  const variableWithValues = { input: inputWithValues, output: outputWithValues }
  return variableWithValues
}

function getDebugBlocks(result, resultDataById) {
  return getResultDebugVariables(result).map(variable => ({
    data: { ...(resultDataById)[variable.id] },
    id: variable.id,
    type: 'output',
    view: 'text',
  }))
}

function getItemByIdFromArray(arr) {
  return arr.reduce((dict, item) => {
      const { id } = item
      dict[id] = item
      return dict
    }, {})
}

function getTemplateBlocks(template, inputVariables, outputVariables, blockType) {
  const inputVariablesById = getItemByIdFromArray(inputVariables)
  const outputVariablesById = getItemByIdFromArray(outputVariables)
  return template.blocks.map((block, index)=> {
    const { id, view } = block
    if (!id) {
      // template markdown ??
      const newId = `${view}-${index}`
      return {
        id: newId,
        ...block,
        name: newId,
        type: 'output',
        noCard: true,
      }
    }

    if (blockType === 'output') {
      return outputVariablesById[id] || inputVariablesById[id]
    }

    return inputVariablesById[id]
  })
}

function getBlockTypeVariableBlocks(variableWithValues, blockType) {
  return variableWithValues.filter(variable => (variable.data.misc || {}).blockType === blockType)
}

function getOutputErrorBlock() {
  return [{
    id: 'error',
    name: 'Error',
    data: { value: 'There was an error while running the tool. Please check your input variables.' },
    view: 'text',
  }]
}

function getBlockTypeForVariable(variable, blockType) {
  const { view } = variable
  return {
    ...variable,
    type: getBlockTypeByView(view, blockType),
  }
}

function makeBlocks({
  blockType,
  tool,
  version,
  template,
  result,
  resultDataById,
}) {
  const variables = getVariableDefinitionsWithValue(tool, resultDataById)
  const inputVariables = variables.input.map((variable) => getBlockTypeForVariable(variable, blockType))
  const outputVariables = variables.output.map((variable) => getBlockTypeForVariable(variable, blockType))

  if (blockType === 'output' && result && result.progress < 0) {
    return getOutputErrorBlock()
  }

  if (blockType === 'debug') {
    return getDebugBlocks(result, resultDataById)
  }

  if (blockType === 'log') {
    return []
  }

  if (template) {
    return getTemplateBlocks(template, inputVariables, outputVariables, blockType).filter(b => b)
  }

  return getBlockTypeVariableBlocks([...inputVariables, ...outputVariables], blockType)
}

function getObjId(obj) {
  return (obj || {}).id
}

function BlocksOld({
  blockType,
  tool,
  version,
  template,
  result,
  resultDataById,
  setResultDataById,
  projectId,
}) {
  const blockSizeById = useRef({ card: {}, noCard: {} })
  const listRef = useRef()
  const [blocks, setBlocks] = useState([])
  const timeoutIdRef = useRef()
  const prevProps = useRef({
    tool: getObjId(tool),
    version: getObjId(version),
    template: getObjId(template),
    result: getObjId(result),
    blockType,
    resultDataById,
  })

  useEffect(() => {
    if (!resultDataById) {
      setBlocks([])
      return
    }

    const toolId = getObjId(tool)
    const versionId = getObjId(version)
    const templateId = getObjId(template)
    const resultId = getObjId(result)

    const {
      tool: prevToolId,
      version: prevVersionId,
      template: prevTemplateId,
      result: prevResultId,
      blockType: prevBlockType,
      resultDataById: prevResultDataById,
    } = prevProps.current

    const isDataNew = blockType !== 'input' && !_.isEqual(prevResultDataById, resultDataById)

    if (toolId !== prevToolId ||
        versionId !== prevVersionId ||
        templateId !== prevTemplateId ||
        resultId !== prevResultId ||
        blockType !== prevBlockType ||
        isDataNew) {
      blockSizeById.current = { card: {}, noCard: {} }
    }

    prevProps.current = {
      tool: toolId,
      version: versionId,
      template: templateId,
      result: resultId,
      blockType,
      resultDataById,
    }

    setBlocks(makeBlocks({
      blockType, tool, version, template, result, resultDataById,
    }))
  }, [blockType, tool, version, template, result, resultDataById])

  const getItemSize = (index) => {
    if (index >= blocks.length) return 50
    const block = blocks[index]
    const { type, view, noCard, id } = block
    const blockHeight = noCard
      ? blockSizeById.current.noCard[`${blockType}-${id}`]
      : blockSizeById.current.card[`${view}-${type}`]
    return blockHeight + 20 || 50
  }
  const toolId = tool ? tool.id : ''
  const templateId = template ? template.id : ''

  const Row = memo(({ index, style, data }) => {
    if (index >= blocks.length) {
      return null
    }
    const block = data[index]
    const { type, view, noCard, id } = block
    const isInputBlockType = blockType === 'input'
    const isShowUploadButton = isInputBlockType

    function getSize(el) {
      if (el) {
        const elHeight = el.getBoundingClientRect().height
        if (noCard) {
          blockSizeById.current.noCard.[`${blockType}-${id}`] = elHeight
        } else {
          // TODO: view types might have different size height. Consider using index as key.
          blockSizeById.current.card[`${view}-${type}`] = elHeight
        }

        if (listRef.current) {
          if (timeoutIdRef.current !== undefined) {
            const id = timeoutIdRef.current
            // console.log('canceled id', id)
            clearTimeout(id)
          }
          const newId = setTimeout(() => {
            // console.log('reset', block, blockSizeById)
            listRef.current.resetAfterIndex(0, true)
          }, 500)
          // console.log('newId', newId)
          timeoutIdRef.current = newId
        }
      }
    }

    // const blockSize = (blockSizeById.current.noCard[`${blockType}-${id}`] || blockSizeById.current.card[`${view}-${type}`])

    const rowContent = noCard
      ? <div style={{ paddingBottom: '4px', paddingTop: '4px' }}>
          <Block {...block} />
        </div>
      : <ProjectPageBlockCard
          projectId={projectId}
          isShowUploadButton={isShowUploadButton}
          block={block}
          onFullScreenClick={() => isInputBlockType && setResultDataById(() => {})}
          setDataValue={({ value }) => setResultDataById(draft => {
            if (blockType !== 'input') {
              return
            }
            const key = `${block.id}-input`
            draft[key].value = value
            delete draft[key].file
            delete draft[key].dataset
            delete draft[key].key
          })}
          setDataFile={({ file, dataset }) => setResultDataById(draft =>{
            if (blockType !== 'input') {
              return
            }
            const key = `${block.id}-input`
            draft[key].dataset = dataset
            draft[key].file = file
            draft[key].misc.key = getRandomString(16)
            delete draft[key].value
          })}
        />

    return (
      <div key={block.id} style={style}>
        {/*
        { blockSize
          ? <div>{rowContent}</div>
          : <div ref={getSize}>{rowContent}</div>
        }
        */}
        <div ref={getSize}>{rowContent}</div>
      </div>)
  }, areEqual)

  const setListRef = useCallback((el) => {
    if (el) {
      listRef.current = el
    }
  }, [])

  return (
    <div style={{ height: 'calc(100vh - 136px - 16px)' }}>
      <AutoSizer key={`${toolId}-${templateId}-${blockType}`}>
        {({ height, width }) => {
          return (
          <List
            height={height}
            itemCount={blocks.length}
            itemSize={getItemSize}
            width={width}
            itemData={blocks}
            ref={setListRef}
          >
            {Row}
          </List>
          )
        }}
      </AutoSizer>
    </div>
  )
}

export default function Blocks({
  blockType,
  tool,
  version,
  template,
  result,
  resultDataById,
  setResultDataById,
  projectId,
}) {
  // TODO: Not sure if we need to do this
  return useMemo(() => {
    return <BlocksOld
      blockType={blockType}
      tool={tool}
      version={version}
      template={template}
      result={result}
      resultDataById={resultDataById}
      setResultDataById={setResultDataById}
      projectId={projectId}
    />
  }, [
    blockType,
    tool,
    version,
    template,
    result,
    resultDataById,
    setResultDataById,
    projectId,
  ])
}

import React from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function ImageView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const label = name || id
  let src = ''
  if (data && data.file) {
    src = data.file.url
  } else if (data && data.value) {
    src = `data:image/png;base64,${data.value}`
  }
  switch (type) {
    default:
    case 'static': {
      // !!! Consider removing width 100%
      return (
        <div>
          <img className={id} src={src} alt={name} style={{ width: '100%' }}/>
        </div>
      )
    }
    case 'input': {
      return (
        <TextField
          label={label}
          value={src}
          variant='filled'
          fullWidth
          multiline
          onChange={event => setData({ value: event.target.value })}
        />
      )
    }
    case 'output': {
      // !!! Consider removing width 100%
      return (
        <div>
          <Typography variant='button' component='h2'>{label}</Typography>
          <img className={id} src={src} alt={name} style={{ width: '100%' }}/>
        </div>
      )
    }
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  mainSection: {
    'flexGrow': 1, // grow width
    'minHeight': '100vh',
    'height': '100vh',
    // 'overflow': 'auto',
    'background': theme.crosscompute.main.background,
    '& > :last-child': {
      marginBottom: theme.spacing(2),
    },
  },
}))

export default function MainSection(props) {
  const classes = useStyles()
  return (
    <Box pr={4} pl={4} {...props} className={classes.mainSection}>
      { props.children }
    </Box>
  )
}

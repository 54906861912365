// TODO: Rewrite to support input, output, log, debug blocks
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { produce } from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import {
  makeStyles,
} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'

import {
  getProject,
  // updateProjectResult,
  // setProjectResultFileIdSummary,
} from '../components/project/projectSlice'
import Blocks from '../components/Blocks'
import ButtonWithDialog from '../components/ButtonWithDialog'
import MySelect from '../components/MySelect'

import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'

import { createResult } from '../api/results'
import { fetchProject, fetchProjectToolVersion } from '../api/project'

// import { SERVER_URL } from '../constants'

import {
  getRandomString,
} from '../macros'
// import Button from '@material-ui/core/Button'
// import PublishResult from '../components/PublishResult'
import ResourceShareDialog from '../components/ResourceShareDialog'
import { useEchoesServer } from '../hooks/echoes'

const useStyles = makeStyles(theme => ({
  runButton: {
    marginRight: theme.spacing(1),
  },
  toggleButtonRoot: {
    color: 'black',
  },
  selectedToggleButton: {
    color: 'white !important',
    background: 'black !important',
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}))

export function useLoadProject(projectId) {
  // const firstRender = useRef(true)
  const dispatch = useDispatch()
  const project = useSelector(getProject)
  // const isLoading = firstRender.current || project.id !== projectId
  const isLoading = project.id !== projectId
  const token = project.token

  useEffect(() => {
    dispatch(fetchProject(projectId))
    // firstRender.current = false
  }, [dispatch, projectId])

  return [project, token, isLoading]
}

function useGetResultVariableDataById(result) {
  const [editableVariableDataById, setEditableVariableDataById] = useState({})
  const resultVariableDataById = useRef()
  const prevResult = useRef(result)
  const isEditing = useRef(false)

  useEffect(() => {
    if (!result || isEditing.current) return
    const inputVariableById = getVariableDataById(result.input, 'input')
    const outputVariableById = getVariableDataById(result.output, 'output')
    const debugVariableById = getVariableDataById(result.debug, 'debug')
    const logVariableById = getVariableDataById(result.log, 'log')
    const variablesById = {
      ...inputVariableById, ...outputVariableById, ...debugVariableById, ...logVariableById }
    resultVariableDataById.current = variablesById
    setEditableVariableDataById(variablesById)
    prevResult.current = result
    isEditing.current = false
  }, [result])

  function getVariableDataById(obj, blockType) {
    const o = obj || {}
    const variables = o.variables || []
    return variables.reduce((dict, variable) => {
      const { id, data } = variable
      const newId = `${id}-${blockType}`
      dict[newId] = { ...data, misc: { blockType, key: getRandomString(16), id } }
      return dict
    }, {})
  }

  function setEditableData(fn, updateIsEditing=true) {
    if (updateIsEditing) {
      isEditing.current = true
    }
    return setEditableVariableDataById(produce(fn))
  }

  function resetResultValues() {
    isEditing.current = false
    setEditableVariableDataById(resultVariableDataById.current)
  }

  const editing = {
    value: isEditing.current,
    resetResultValues,
    ref: isEditing,
  }

  return [
    prevResult.current === result ? editableVariableDataById : null,
    setEditableData,
    editing,
  ]
}

function getFirstElement(arr) {
  if (arr.length === 0)
    return
  return arr[0]
}

function getSelectedTool(tools, selectedToolId) {
  if (!tools) return
  if (!selectedToolId)
    return getFirstElement(tools)
  return tools.find(tool => tool.id === selectedToolId) || getFirstElement(tools)
}

function getSelectedVersion(tool) {
  if (!tool) return
  const version = tool.version
  return version
}

function getSelectedTemplate(tool, selectedTemplateId, templateType) {
  if (!tool) return
  const templates = tool[templateType] && tool[templateType].templates
  if (!templates) return

  if (selectedTemplateId === undefined) {
    return templates[0]
  }

  return templates.find(template => template.id === selectedTemplateId)
}

function getSelectedResult(results, resultId, tool, version) {
  if (!results || !tool || !version) return
  const toolId = tool.id
  const versionId = version.id
  const toolResults = results
    .filter(result => result.tool.id === toolId && result.tool.version.id === versionId)
  const result = toolResults.find(result => result.id === resultId)
  return result || getFirstElement(toolResults)
}

const INPUT_BLOCK_MODE = 'INPUT_BLOCK_MODE'
const OUTPUT_BLOCK_MODE = 'OUTPUT_BLOCK_MODE'
const DEBUG_BLOCK_MODE = 'DEBUG_BLOCK_MODE'
const LOG_BLOCK_MODE = 'LOG_BLOCK_MODE'
const INITIAL_BLOCK_MODE = OUTPUT_BLOCK_MODE

function getVariableTypeFromBlockMode(blockMode) {
  return {
    [INPUT_BLOCK_MODE]: 'input',
    [OUTPUT_BLOCK_MODE]: 'output',
    [DEBUG_BLOCK_MODE]: 'debug',
    [LOG_BLOCK_MODE]: 'log',
  }[blockMode] || 'output'
}

function makeOption(obj) {
  return { id: obj.id, value: obj.id, name: obj.name }
}

function getObjId(obj) {
  return obj ? obj.id : null
}

function useSelectedOptionByName(project, isLoading, blockMode) {
  const [selectedOptionIdByName, setSelectedOptionIdByName] = useState({})

  const selectedOptionsByName = useMemo(() => {
    if (isLoading) return { tool: {}, version: {}, template: {}, result: {} }
    const selectedToolId = selectedOptionIdByName.tool
    // const selectedVersionId = selectedOptionIdByName.version
    const selectedTemplateId = selectedOptionIdByName.template
    const selectedResultId = selectedOptionIdByName.result
    const tool = getSelectedTool(project.tools, selectedToolId)
    const version = getSelectedVersion(tool)
    const template = getSelectedTemplate(tool, selectedTemplateId, getVariableTypeFromBlockMode(blockMode))
    const result = getSelectedResult(project.results, selectedResultId, tool, version)
    const newSelectedOptionByName = {
      tool: { id: getObjId(tool), selected: tool },
      version: { id: getObjId(version), selected: version },
      template: { id: getObjId(template), selected: template },
      result: { id: getObjId(result), selected: result },
    }
    return newSelectedOptionByName
  }, [isLoading, project, blockMode, selectedOptionIdByName])

  function updateSelectedIdByName(idByName = {}) {
    setSelectedOptionIdByName(prevState => ({ ...prevState, ...idByName }))
  }

  const selectedToolId = selectedOptionsByName.tool.id
  const selectedVersionId = selectedOptionsByName.version.id
  // const selectedTemplateId = selectedOptionsByName.template.id
  // const selectedResultId = selectedOptionsByName.result.id
  const isShowInputBlock = blockMode === INPUT_BLOCK_MODE
  

  const resultOptions = useMemo(() => {
    if (isLoading) return []
    const results = project.results
      .filter(result => result.tool.id === selectedToolId && result.tool.version.id === selectedVersionId)
    const resultOptions = results.map(result => makeOption(result))
    return resultOptions
  }, [isLoading, selectedToolId, selectedVersionId, project])

  const versionOptions = useMemo(() => {
    if (isLoading) return []
    const tool = project.tools.find(tool => tool.id === selectedToolId)
    if (!tool) return []
    const versionOptions = tool.versions.map(version => makeOption(version))
    return versionOptions
  }, [isLoading, selectedToolId, project.tools])

  const templateOptions = useMemo(() => {
    if (isLoading) return []
    const templateType = isShowInputBlock ? 'input' : 'output'
    const selectedTool = project.tools.find(tool => tool.id === selectedToolId)
    if (!selectedTool) return []
    const templates = selectedTool[templateType].templates || []
    return templates.map(template => makeOption(template))
  }, [isLoading, selectedToolId, isShowInputBlock, project.tools])

  const optionsByName = { result: resultOptions, version: versionOptions, template: templateOptions }
  
  return [selectedOptionsByName, optionsByName, updateSelectedIdByName]
}


export default function ProjectPage({
  match: {
    params: {
      projectId,
    },
  },
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [project, token, isLoading] = useLoadProject(projectId)
  const [blockMode, setBlockMode] = useState(INITIAL_BLOCK_MODE)
  const isShowInputBlock = blockMode === INPUT_BLOCK_MODE
  const [selectedOptionByName, optionsByName, setSelectedOptionIdByName] = useSelectedOptionByName(project, isLoading, blockMode)

  const { result: resultOptions, version: versionOptions, template: templateOptions } = optionsByName
  const { selected: selectedTool, id: selectedToolId } = selectedOptionByName.tool
  const { selected: selectedVersion, id: selectedVersionId } = selectedOptionByName.version
  const { selected: selectedTemplate, id: selectedTemplateId } = selectedOptionByName.template
  const { selected: selectedResult, id: selectedResultId } = selectedOptionByName.result
  const [resultDataById, setResultDataById, editing] = useGetResultVariableDataById(selectedResult)
  const {
    resetResultValues, ref: isEditingResultRef,
  } = editing
  useEchoesServer(token, setResultDataById)

  const newResultName = `${selectedResult && selectedResult.name} > New`

  function updateSelectedOptionIdByName(valueById) {
    isEditingResultRef.current = false
    setSelectedOptionIdByName(valueById)
  }

  const resultId = selectedResultId
  const templateId = selectedTemplateId

  return (
    <MainSection>
      <HeaderSection>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          flexGrow={1}
        >
          <Box display='flex' alignItems='flex-end'>
            <Breadcrumbs
              component='div'
              separator={<NavigateNextIcon />}
              aria-label='breadcrumb'
              color='inherit'
            >
              <Link color='inherit' variant='h6' to='/projects' component={RouterLink}>
                Projects
              </Link>
              <Typography variant='h6'>{project.name}</Typography>
            </Breadcrumbs>
            <Box ml={1}>
              <Typography
                size='small'
                variant='caption'
                component={RouterLink}
                color='inherit'
                to={`/edit-project/${projectId}`}
              >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box display='flex'>
            {/*
            <PublishResult result={selectedResult} project={project} results={resultOptions || []} templateId={selectedTemplateId}></PublishResult>
            */}
            <ResourceShareDialog
              projectId={projectId}
              resultId={resultId}
              templateId={templateId}
            />

            <div className={classes.runButton}>
              <ButtonWithDialog
                isEnabled={isShowInputBlock}
                label={newResultName}
                handleOnRun={(toolName) => {
                  if (!isShowInputBlock) return
                  const inputVariables = Object.keys(resultDataById)
                    .filter(id => resultDataById[id].misc.blockType === 'input' )
                    .map(id=> {
                      const { misc, ...data } = resultDataById[id]
                      return { data, id: misc.id }
                    })
                  const outputVariables = Object.keys(resultDataById)
                    .filter(id => resultDataById[id].misc.blockType === 'output')
                    .map(id => {
                      const { misc } = resultDataById[id]
                      return { data:  {}, id: misc.id }
                    })
                  dispatch(createResult({
                    toolName,
                    projectId: project.id,
                    toolId: selectedToolId,
                    toolVersionId: selectedVersionId,
                    inputVariables,
                    outputVariables,
                  })).then(id => {
                    updateSelectedOptionIdByName({ result: id })
                    setBlockMode(OUTPUT_BLOCK_MODE)
                  })
                }}
              />
            </div>
            <ToggleButtonGroup
              exclusive
              size='small'
              value={blockMode}
              aria-label='select input or output'
              onChange={(e, val) => {
                if (val !== null) setBlockMode(val)
                resetResultValues()
              }}
            >
              <ToggleButton
                value={INPUT_BLOCK_MODE}
                aria-label='input'
                classes={{
                  root: classes.toggleButtonRoot,
                  selected: classes.selectedToggleButton,
                }}
              >
                Input
              </ToggleButton>
              <ToggleButton
                value={OUTPUT_BLOCK_MODE}
                aria-label='output'
                classes={{
                  root: classes.toggleButtonRoot,
                  selected: classes.selectedToggleButton,
                }}
              >
                Output
              </ToggleButton>
    {/*
              { selectedResult && selectedResult.debug &&
                <ToggleButton
                  value={DEBUG_BLOCK_MODE}
                  aria-label='debug'
                  classes={{
                    root: classes.toggleButtonRoot,
                    selected: classes.selectedToggleButton,
                  }}
                >
                  Debug
                </ToggleButton>
              }
              { selectedResult && selectedResult.log &&
                <ToggleButton
                  value={LOG_BLOCK_MODE}
                  aria-label='debug'
                  classes={{
                    root: classes.toggleButtonRoot,
                    selected: classes.selectedToggleButton,
                  }}
                >
                  LOG
                </ToggleButton>
              }
    */}
            </ToggleButtonGroup>
          </Box>
        </Box>
      </HeaderSection>
      <Grid container spacing={2} className={classes.marginTop}>
        <Grid item xs={12} sm={3}>
          <MySelect
            label='Tool'
            value={selectedToolId}
            options={project.tools && project.tools.map(
              tool => ({ id: tool.id, value: tool.id, name: tool.name }))}
            onChange={(e) => {
              updateSelectedOptionIdByName({ tool: e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MySelect
            label='Version'
            value={selectedVersionId}
            options={versionOptions || []}
            onChange={(e) => {
              const versionId = e.target.value
              dispatch(fetchProjectToolVersion(selectedToolId, versionId))
              updateSelectedOptionIdByName({ version: versionId })
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <MySelect
            label='Template'
            emptyOptionLabel='Variables Only'
            value={selectedTemplateId}
            options={templateOptions || []}
            onChange={(e) => updateSelectedOptionIdByName({ template: e.target.value })}
          />
        </Grid>
        { false &&
          <Grid item xs={12} sm={2}>
            <MySelect
              label='Variable'
              emptyOptionLabel='All'
            />
          </Grid>
        }
        <Grid item xs={12} sm={3}>
          <MySelect
            label='Result'
            value={selectedResultId}
            options={resultOptions || []}
            onChange={(e) => {
              updateSelectedOptionIdByName({ result: e.target.value })
            }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.marginTop}>
        <Grid item xs={12}>
          { !isLoading &&
            <Blocks
              blockType={getVariableTypeFromBlockMode(blockMode)}
              tool={selectedTool}
              version={selectedVersion}
              template={selectedTemplate}
              result={selectedResult}
              resultDataById={resultDataById}
              setResultDataById={setResultDataById}
              projectId={project.id}
            />
          }
        </Grid>
      </Grid>
    </MainSection>
  )
}

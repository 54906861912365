import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    urls: { enter: {}, leave: '' },
    // user: { name: '', imageUrl: '' },
  },
  reducers: {
    set(state, action) {
      const { urls, user } = action.payload
      state.urls = urls
      state.user = user
    },
    setUnauthorized(state, action) {
      delete state.user
    },
  },
})

export const { set: setAuth, setUnauthorized } = authSlice.actions

export const getAuth = state => state.auth
export const getIsUserAuthenticated = state => state.auth.user

export default authSlice.reducer

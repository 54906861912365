import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    height: '300px',
    // width: '100%',
    overflow: 'hidden',
  },
  /*
  fullHeight: {
    height: '100%',
  },
  */
})

/*
function formatColumns(columns = []) {
  const formattedColumns = columns.map(column => ({
    headerName: column,
    field: column,
    flex: 1, // make all columns the same width
    // TODO: Think of how to choose type
  }))
  return formattedColumns
}

function formatRows(rows = [], columns = []) {
  const formattedRows = rows.map((row, index) => {
    const obj = {}
    for (var j = 0; j < row.length; j++) {
      obj[columns[j]] = row[j]
    }
    return { ...obj, id: index }
  })
  return formattedRows
}
*/

export default function TableView({ id, name, type, data, setData, fullHeight }) {
  // console.log('TableView', id, name, type, data)
  const classes = useStyles()
  const defaultValue = { columns: [], rows: [] }
  const summary = data.file && data.file.summary
  const { columns, rows } = data.value || summary || defaultValue

/*
  // const label = name || id
  // TODO: Generalize
  const formattedColumns = formatColumns(columns)
  const formattedRows = formatRows(rows, columns)

  switch (type) {
    case 'summary': {
      const summary = data.file && data.file.summary
      const { columns, rows } = data.value || summary || defaultValue
      /*
      const formattedColumns = formatColumns(columns)
      const formattedRows = formatRows(rows, columns)
      <DataGrid rows={formattedRows} columns={formattedColumns} />
        <div className={classes.root}>
          'whee'
        </div>
      return (
        <TableContainer component={Paper} className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell>{column}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow>
                  {row.map(column => (
                    <TableCell>{column}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    // TODO: Consider case 'print' instead of 'static'
    case 'static': {
      return (
        <div className={classes.root}>
          <DataGrid rows={formattedRows} columns={formattedColumns} />
        </div>
      )
    }
    case 'input': {
      return (
        <h1>tableView input</h1>
      )
    }
    case 'output': {
      // TODO: Try setting toolbar background color
      return (
        <div className={clsx(classes.root, fullHeight && classes.fullHeight)}>
          <DataGrid rows={formattedRows} columns={formattedColumns} />
        </div>
      )
    }
    default: {
    }
  }
*/
  return (
    <div className={classes.root}>
      <Table className={id} size='small'>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              {row.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

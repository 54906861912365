import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
// import Typography from '@material-ui/core/Typography'

// import SignOutButton from './components/auth/SignOutButton'

import AcknowledgementsPage from './pages/AcknowledgementsPage'
// import AuthenticationsPage from './pages/AuthenticationsPage'
import BoardsPage from './pages/DashboardPage'
import EmptyProjectsPage from './pages/EmptyProjectsPage'
import FullscreenBlockPage from './pages/FullscreenBlockPage'
import FileTableScreenPage from './pages/FileTableScreenPage'
import FileImageScreenPage from './pages/FileImageScreenPage'
import FileMapScreenPage from './pages/FileMapScreenPage'
// import MapFullScreenPage from './pages/MapFullScreenPage'
import NotAuthorizedPage from './pages/NotAuthorizedPage'
import PrintDocumentPage from './pages/PrintDocumentPage'
import PrintsPage from './pages/PrintsPage'
import ProjectCreatePage from './pages/ProjectCreatePage'
import ProjectEditPage from './pages/ProjectEditPage'
import ProjectPage from './pages/ProjectPage'
import ProjectsPage from './pages/ProjectsPage'
// import ResultTemplatePage from './pages/ResultTemplatePage'
import SettingsPage from './pages/SettingsPage'
import SignInPage from './pages/SignInPage'
// import TableFullScreenPage from './pages/TableFullScreenPage'
import { fetchAuth } from './api/auth'

import ProtectedRoute from './components/auth/ProtectedRoute'
import {
  ACKNOWLEDGEMENTS_PATH,
  AUTHENTICATIONS_PATH,
  BOARDS_PATH,
  DATASETS_TABLE_FULL_SCREEN_VIEW,
  DATASETS_IMAGE_FULL_SCREEN_VIEW,
  DATASETS_MAP_FULL_SCREEN_VIEW,
  // FILES_TABLE_FULL_SCREEN_VIEW,
  // FILES_MAP_FULL_SCREEN_VIEW,
  LEGACY_URL,
  NOT_AUTHORIZED_PATH,
  PRINTS_PATH,
  PRINT_DOCUMENT_PATH,
  PROFILES_PATH,
  PROJECTS_PATH,
  PROJECT_PATH,
  PROJECT_WIZARD_PATH,
  PROJECT_EDIT_WIZARD_PATH,
  RESULT_TEMPLATE_PATH,
  ROOT_PATH,
  SETTINGS_PATH,
  SIGN_IN_PATH,
  RESULT_PATH,
  // REPORT_PATH,
  FORM_PATH,
  FORM_TEMPLATE_PATH,
} from './constants'
import './App.css'
// import ProfilePage from './pages/ProfilePage'
import ResultPage from './pages/ResultPage'
// import ReportPage from './pages/ReportPage'
import FormPage from './pages/FormPage'

const colors = {
  gray: '#e3e4ee',
  lightGray: 'rgba(255, 255, 255, 0.5)',
  blue: '#386be0',
  green: '#11c77e',
}

const theme = createMuiTheme({
  crosscompute: {
    colors: {
      gray: colors.gray,
      blue: colors.blue,
      green: colors.green,
      lightGray: colors.lightGray,
    },
    main: {
      background: colors.gray,
    },
    paper: {
      secondary: colors.lightGray,
    },
    box: {
      background: colors.white,
    },
    button: {
      primary: colors.blue,
      secondary: colors.green,
    },
  },
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.green,
    },
  },
})

export default function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  // const { user } = useSelector(getAuth)

  function LegacyRedirect() {
    window.location.href = LEGACY_URL + location.pathname
    return null
  }

  useEffect(function () {
    dispatch(fetchAuth())
  })

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path={PRINT_DOCUMENT_PATH}><PrintDocumentPage /></Route>
        <Route path={SIGN_IN_PATH} component={SignInPage}></Route>
        <Route path={AUTHENTICATIONS_PATH} component={SignInPage}></Route>
    {/*
        <Route path={AUTHENTICATIONS_PATH}><AuthenticationsPage /></Route>
    */}
        <Route path={ACKNOWLEDGEMENTS_PATH}><AcknowledgementsPage /></Route>

        <ProtectedRoute path={'/test'} component={PrintDocumentPage} />
        <ProtectedRoute path={'/test2'} component={PrintDocumentPage} />
        <ProtectedRoute path={'/test3'} component={PrintDocumentPage} />
        <ProtectedRoute path={'/test4'} component={EmptyProjectsPage} />
        <ProtectedRoute path={'/fullscreen/:projectId/:blockId'} component={FullscreenBlockPage} />

    {/*
        <ProtectedRoute path={DATASETS_TABLE_FULL_SCREEN_VIEW} component={TableFullScreenPage} />
        <ProtectedRoute path={DATASETS_MAP_FULL_SCREEN_VIEW} component={MapFullScreenPage} />
    */}

        <ProtectedRoute path={DATASETS_TABLE_FULL_SCREEN_VIEW} component={FileTableScreenPage} excludeNavbar />
        <ProtectedRoute path={DATASETS_IMAGE_FULL_SCREEN_VIEW} component={FileImageScreenPage} excludeNavbar />
        <ProtectedRoute path={DATASETS_MAP_FULL_SCREEN_VIEW} component={FileMapScreenPage} excludeNavbar />

    {/*
        <ProtectedRoute path={FILES_TABLE_FULL_SCREEN_VIEW} component={FileTableScreenPage} excludeNavbar/>
        <ProtectedRoute path={FILES_MAP_FULL_SCREEN_VIEW} component={FileMapScreenPage} excludeNavbar/>
    */}

        <ProtectedRoute path={PROJECT_WIZARD_PATH} component={ProjectCreatePage} />
        <ProtectedRoute path={PROJECT_EDIT_WIZARD_PATH} component={ProjectEditPage} />
        <ProtectedRoute path={PRINTS_PATH} component={PrintsPage} />
        <ProtectedRoute path={BOARDS_PATH} component={BoardsPage} />
        <ProtectedRoute path={PROJECT_PATH} component={ProjectPage} />
        <ProtectedRoute path={PROJECTS_PATH} component={ProjectsPage} />
        <ProtectedRoute path={SETTINGS_PATH} component={SettingsPage} />
    {/*
        <ProtectedRoute path={PROFILES_PATH} component={ProfilePage} />
    */}
        <ProtectedRoute path={ROOT_PATH} component={ProjectsPage} exact />

        <Route path={RESULT_TEMPLATE_PATH} component={ResultPage} />
        <Route path={RESULT_PATH} component={ResultPage} />
        <Route path={FORM_TEMPLATE_PATH} component={FormPage} />
        <Route path={FORM_PATH} component={FormPage} />

        <Route path={NOT_AUTHORIZED_PATH} component={NotAuthorizedPage} />
        <Route component={LegacyRedirect}/>
      </Switch>
    </ThemeProvider>
  )
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NavbarSection from '../sections/NavbarSection'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
  mainWrapper: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'hidden',
  },
}))

export default function DefaultTemplate(props) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavbarSection />
      <div className={classes.mainWrapper}>
        { props.children }
      </div>
    </div>
  )
}

import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  selected: {
    color: theme.crosscompute.colors.blue,
  },
  label: {
    display: 'block',
  },
}))

export default function NavbarButton({ text, Icon, to, external }) {
  const classes = useStyles()

  const renderLink = useMemo(() => React.forwardRef(
    (itemProps, ref) => (
      external
      ? <a href={to} ref={ref} {...itemProps} />
      : <NavLink to={to} ref={ref} {...itemProps} activeClassName={classes.selected} />
    )), [classes.selected, external, to])

  return (
    <ListItem disableGutters style={{ display: 'block', textAlign: 'center' }}>
      <IconButton
        component={renderLink}
        to={to}
        disableRipple
        style={{ paddingBottom: '.2em' }}
        classes={{ label: classes.label }}
      >
        {Icon}
        <Typography>
          {text}
        </Typography>
      </IconButton>
    </ListItem>
  )
}

import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'
// import { getProject } from '../components/project/projectSlice'
// import { fetchProject } from '../api/project'

export default function FullscreenBlockPage({
  match: {
    params: {
      projectId,
    },
  },
  location: {
    state = {},
  },
}) {
  // const dispatch = useDispatch()
  // const [block, setBlock] = useState({})
  // const project = useSelector(getProject)
  // const tools = project.tools

  useEffect(() => {
    // load files
    // dispatch(fetchProject(projectId))
  }, [])

  return (
    <MainSection>
      <HeaderSection title='Edit Dataset'>
        <button>Create new Block</button>
      </HeaderSection>
      <Box display='flex'>
      </Box>
    </MainSection>
  )
}

import React from 'react'
import JSONEditor from 'jsoneditor/dist/jsoneditor-minimalist'
import 'jsoneditor/dist/jsoneditor.css'

const defaultOptions = {
  search: false,
  history: false,
  navigationBar: false,
  statusBar: true,
  sortObjectKeys: false,
}

export default class JsonEditor extends React.Component {
  getOptions(isEditing) {
    const {
      name,
      json,
      onChange,
      mode,
      modes,
      ...rest
    } = this.props
    const options = {
      json,
      name,
      mode: 'view',
      modes: isEditing ? ['view', 'tree'] : ['view'],
      onChangeJSON: onChange,
      ...defaultOptions,
      ...rest,
    }
    return options
  }
  componentDidMount() {
    this.jsoneditor = new JSONEditor(this.container, this.getOptions(this.props.isEditing))
  }

  componentWillUnmount() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEditing !== this.props.isEditing) {
      this.jsoneditor.destroy()
      this.jsoneditor = new JSONEditor(this.container, this.getOptions(this.props.isEditing))
    }

    this.jsoneditor.update(this.props.json)
    this.jsoneditor.setName(this.props.name)
  }
  
  render() {
    return <div {...this.props.containerProps} ref={elem => this.container = elem} />
  }

}

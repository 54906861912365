import React from 'react'
import { useState } from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'
import DeckGL from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { DrawPolygonMode, DrawLineStringMode, DrawPointMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { StaticMap } from 'react-map-gl'
import { HeatmapLayer } from '@deck.gl/aggregation-layers'

export default function MapView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const value = (data && data.value !== '') ? data.value : { type: 'FeatureCollection', features: [] }

  const layer = new GeoJsonLayer({
    // id: 'geojson-layer',
    data: value,
    // pickable: true,
    // stroked: false,
    // filled: true,
    // extruded: true,
    // lineWidthScale: 20,
    // lineWidthMinPixels: 2,
    // getFillColor: [160, 160, 180, 200],
    // // getLineColor: d => colorToRGBArray(d.properties.color),
    // getRadius: 100,
    // getLineWidth: 1,
    // getElevation: 30
  })

  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

  const computeAvg = (elem) => {
    if (data.value.length <= 0) {
      return 0
    }
    let sum = 0
    for (var i = 0; i < data.value.length; i++) {
      sum += data.value[i][elem]
    }
    return sum / data.value.length
  }

  const INITIAL_VIEW_STATE = {
    longitude: computeAvg(0),
    latitude: computeAvg(1),
    zoom: 9,
    maxZoom: 16,
    pitch: 0,
    bearing: 0,
  }

  const label = name || id
  // console.log('data', data)
  switch (type) {
    default:
    case 'static': {
      return (
        <div>
          <Typography>{label}</Typography>
          <div style={{ position: 'relative', height: '400px' }}>
            <DeckGL
              initialViewState={INITIAL_VIEW_STATE}
              controller={true}
              layers={[layer]}
            >
              <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
            </DeckGL>
          </div>
        </div>
      )
    }
    case 'input': {
      return (
        <EditableMap
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
          initialData={data.value}
          label={label}
        />
      )
    }
    case 'output': {
      return (
        <HeatMap
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
          initialData={data.value}
          label={label}
        />
      )
    }
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        'color': '#1A76D1',
        'border': '1px solid #1A76D1',
        '&$selected': {
          color: '#0052a2',
          border: '1px solid #0052a2',
        },
      },
    },
  },
})

export function EditableMap({ initialViewState, controller, initialData, label }) {
  const selectedFeatureIndexes = []
  const [data, setData] = useState(initialData)

  const DRAW_MODE_ADD_LINE = 'add > line'
  const DRAW_MODE_ADD_POINT = 'add > point'
  const DRAW_MODE_ADD_POLYGON = 'add > polygon'

  const [drawMode, setDrawMode] = useState(DRAW_MODE_ADD_LINE)

  const getMapMode = (drawMode) => {
    // console.log('getting the map mode', drawMode)
    const mapMode = {
      [DRAW_MODE_ADD_LINE]: DrawLineStringMode,
      [DRAW_MODE_ADD_POINT]: DrawPointMode,
      [DRAW_MODE_ADD_POLYGON]: DrawPolygonMode,
    }[drawMode]
    return mapMode
  }

  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

  const mapMode = getMapMode(drawMode)

  const layer = new EditableGeoJsonLayer({
    // id: 'geojson-layer',
    data: data,
    mode: mapMode,
    selectedFeatureIndexes,

    onEdit: ({ updatedData }) => {
      setData(updatedData)
    },
  })

  // console.log('THE MAP MODE IS', mapMode)
  return (
    <ThemeProvider theme={theme}>
      <Typography>{label}</Typography>
      <ToggleButtonGroup
        color="primary"
        value={drawMode}
        exclusive
      >
        <ToggleButton
          value={DRAW_MODE_ADD_LINE}
          onClick={() => setDrawMode(DRAW_MODE_ADD_LINE)}
        >
          Draw Line
        </ToggleButton>
        <ToggleButton
          value={DRAW_MODE_ADD_POINT}
          onClick={() => setDrawMode(DRAW_MODE_ADD_POINT)}
        >
          Draw Point
        </ToggleButton>
        <ToggleButton
          value={DRAW_MODE_ADD_POLYGON}
          onClick={() => setDrawMode(DRAW_MODE_ADD_POLYGON)}
        >
          Draw Polygon
        </ToggleButton>
      </ToggleButtonGroup>
      <div style={{ position: 'relative', height: '400px' }}>
        <DeckGL
          initialViewState={initialViewState}
          controller={true}
          layers={[layer]}
        >
          <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
        </DeckGL>
      </div>
    </ThemeProvider>
  )
}

export function HeatMap({
  initialViewState,
  initialData,
  controller,
  label,
  intensity = 1,
  threshold = 0.03,
  radiusPixels = 30,
}) {
  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN
  // const data = 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/screen-grid/uber-pickup-locations.json'

  const layer = new HeatmapLayer({
    data: initialData,
    // data,
    // id: 'heatmp-layer',
    pickable: false,
    getPosition: d => [d[0], d[1]],
    getWeight: d => d[2],
    radiusPixels,
    intensity,
    threshold,
  })

  return (
    <div style={{ width: '100%' }}>
      <Typography>{label}</Typography>
      <div style={{ position: 'relative', height: '400px' }}>
        <DeckGL
          initialViewState={initialViewState}
          controller={true}
          layers={[layer]}
        >
          <StaticMap
            reuseMaps
            preventStyleDiffing={true}
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
          />
        </DeckGL>
      </div>
    </div>
  )
}

import React, { useCallback, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import turfBbox from '@turf/bbox'
import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { WebMercatorViewport } from '@math.gl/web-mercator'
import { feature as turfFeature, featureCollection as turfFeatureCollection } from '@turf/helpers'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
// import Typography from '@material-ui/core/Typography'
import DeckGL from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { DrawPolygonMode, DrawLineStringMode, DrawPointMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { Layer, StaticMap, Source } from 'react-map-gl'
import { default as EditMap } from './editable-map'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

export const polygonLayer = {
  type: 'fill',
  paint: {
    'fill-color': 'black',
  },
  filter: ['==', '$type', 'Polygon'],
}

export const convexHullLayer = {
  type: 'fill',
  paint: {
    'fill-color': 'rgba(0, 0, 0, .3)',
  },
  filter: ['==', '$type', 'Polygon'],
}

export const circleLayer = {
  type: 'circle',
  paint: {
    'circle-color': 'black',
  },
  filter: ['==', '$type', 'Point'],
}

export const lineLayer = {
  type: 'line',
  paint: {
    'line-color': 'black',
  },
  filter: ['==', '$type', 'LineString'],
}

export function getMapViewportFromBbox(
  bBox = [],
  width,
  height,
) {
  if (!bBox || !bBox.length === 0) return
  const viewport = new WebMercatorViewport({ width, height })
  const paddingWidth = width * .1
  const paddingHeight = height * .1
  const newViewport =  viewport.fitBounds(bBox, {
    maxZoom: 20,
    padding: {
      top: paddingHeight,
      bottom: paddingHeight,
      left: paddingWidth,
      right: paddingWidth,
    },
  })
  return {
    ...newViewport,
    zoom: newViewport.zoom < 0 ? 0 : newViewport.zoom,
  }
}

export function getBoundingBoxFromFeatures(features = []) {
  if (features.length === 0) return null
  // console.log('feature count', features.length)
  const turfFeatures = []

  // Loop through each "feature"
  for (let i = 0; i < features.length; i++) {
    const geometry = features[i].geometry
    const feature = turfFeature(geometry)
    turfFeatures.push(feature)
  }

  const collection = turfFeatureCollection(turfFeatures)
  const bbox = turfBbox(collection)
  const [minX, minY, maxX, maxY] = bbox

  // TODO: Fix properly
  if (minX === Infinity) return null

  const mapCorner1 = [minX, minY]
  const mapCorner2 = [maxX, maxY]
  return [mapCorner1, mapCorner2]
}

const INITIAL_VIEW_STATE = {
  longitude: -112.0372,
  latitude: 46.608058,
  zoom: 21,
  pitch: 0,
  bearing: 0,
}

const defaultValue = { type: 'FeatureCollection', features: [] }

const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: '300px',
    width: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
})

export function useGetViewState(features) {
  const mapRef = useRef()
  const [viewState, setViewState] = useState(INITIAL_VIEW_STATE)

  const setMapRef = useCallback((nodeEl) => {
    if (nodeEl) {
      const { width , height } = nodeEl.getBoundingClientRect()
      if (width === 0 || height === 0) return
      const bBox = getBoundingBoxFromFeatures(features)
      if (!bBox) return
      const viewport = getMapViewportFromBbox(bBox, width, height)
      if (!viewport) return
      const { longitude, latitude, zoom } = viewport
      if (viewState.zoom < zoom) return
      setViewState({ longitude, latitude, zoom })
    }
    mapRef.current = nodeEl
  }, [features, viewState.zoom])

  return [viewState, setMapRef]
}

export default function MapView({
  id,
  name,
  type,
  data,
  setData,
  onSaveClick,
  onCancelClick,
  fullHeight,
}) {
  const classes = useStyles()
  const value = data.value || defaultValue

  const [viewState, setMapRef] = useGetViewState(value.features)

  // const label = name || id

  const layer = new GeoJsonLayer({
    data: value,
  })

  switch (type) {
    default:
    case 'summary': {
      return (
        <SummaryMap id={id} data={data} />
      )
    }
    case 'static': {
      return (
        <div ref={setMapRef} style={{ position: 'relative', height: '300px' }}>
          <StaticMap
            mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
            width='100%'
            height='100%'
            {...viewState}
          >
            <Source id={id} type='geojson' data={value}>
              <Layer {...polygonLayer} />
              <Layer {...circleLayer} />
              <Layer {...lineLayer} />
            </Source>
          </StaticMap>
        </div>
      )
    }
    case 'input': {
      return (
        <EditMap
          data={data}
          setData={setData}
          onSaveClick={onSaveClick}
          onCancelClick={onCancelClick}
          fullHeight
        />
      )
    }
    case 'output': {
      return (
        <div ref={setMapRef} className={clsx(classes.root, fullHeight && classes.fullHeight)}>
          <DeckGL
            initialViewState={viewState}
            controller={true}
            layers={[layer]}
            data={value}
            onClick={(e) => { console.log('DECKGL', e) } }
          >
            <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
          </DeckGL>
        </div>
      )
    }
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        'color': '#1A76D1',
        'border': '1px solid #1A76D1',
        '&$selected': {
          color: '#0052a2',
          border: '1px solid #0052a2',
        },
      },
    },
  },
})

export function SummaryMap({ id, data }) {
  const summary = data.file && data.file.summary
  const value = data.value || summary || defaultValue
  const convexHull = value && value.properties && value.properties.convexHull
  const convexHullFeature = useMemo(() => (convexHull && [{ type: 'Feature', geometry: convexHull }]), [convexHull])
  const [viewState, setMapRef] = useGetViewState(convexHullFeature || value.features)
  return (
    <div ref={setMapRef} style={{ position: 'relative', height: '300px' }}>
      <StaticMap
        mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
        width='100%'
        height='100%'
        {...viewState}
      >
        { convexHull &&
          <Source id={`${id}-convexHull`} type='geojson' data={convexHull}>
            <Layer {...convexHullLayer} />
          </Source>}
        <Source id={id} type='geojson' data={value}>
          <Layer {...polygonLayer} />
          <Layer {...circleLayer} />
          <Layer {...lineLayer} />
        </Source>
      </StaticMap>
    </div>
  )
}

/*
  <Source type='geojson' data={{'type': 'FeatureCollection', 'features': [{type: 'Feature', geometry: convexHull}]}}>
*/

const selectedFeatureIndexes = []

const DRAW_MODE_ADD_LINE = 'add > line'
const DRAW_MODE_ADD_POINT = 'add > point'
const DRAW_MODE_ADD_POLYGON = 'add > polygon'
export function EditableMap({ data, setData, fullHeight }) {
  const classes = useStyles()
  const value = data.value || defaultValue
  const [drawMode, setDrawMode] = useState(DRAW_MODE_ADD_LINE)
  const [viewState, setMapRef] = useGetViewState(value.features)

  const getMapMode = (drawMode) => {
    // console.log('getting the map mode', drawMode)
    const mapMode = {
      [DRAW_MODE_ADD_LINE]: DrawLineStringMode,
      [DRAW_MODE_ADD_POINT]: DrawPointMode,
      [DRAW_MODE_ADD_POLYGON]: DrawPolygonMode,
    }[drawMode]
    return mapMode
  }

  const layer = new EditableGeoJsonLayer({
    // id: 'geojson-layer',
    data: value,
    mode: getMapMode(drawMode),
    selectedFeatureIndexes,

    onEdit: ({ updatedData, editType }) => {
      if (editType === 'addFeature') {
        setData({ value: updatedData })
      }
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <div ref={setMapRef} style={{ position: 'relative' }} className={clsx(classes.root, fullHeight && classes.fullHeight)}>
        <div style={{ position: 'absolute', top: 0, left: 0, zIndex: '1', background: 'white' }}>
          <ToggleButtonGroup
            color='primary'
            value={drawMode}
            size='small'
            exclusive
          >
            <ToggleButton
              value={DRAW_MODE_ADD_LINE}
              onClick={() => setDrawMode(DRAW_MODE_ADD_LINE)}
            >
              Draw Line
            </ToggleButton>
            <ToggleButton
              value={DRAW_MODE_ADD_POINT}
              onClick={() => setDrawMode(DRAW_MODE_ADD_POINT)}
            >
              Draw Point
            </ToggleButton>
            <ToggleButton
              value={DRAW_MODE_ADD_POLYGON}
              onClick={() => setDrawMode(DRAW_MODE_ADD_POLYGON)}
            >
              Draw Polygon
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div style={{ position: 'relative', height: '100%' }}>
          <DeckGL
            initialViewState={viewState}
            controller={true}
            layers={[layer]}
          >
            <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
          </DeckGL>
        </div>
      </div>
    </ThemeProvider>
  )
}

import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
// import Typography from '@material-ui/core/Typography'

import { SERVER_URL } from '../constants'

export default function ImageView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const defaultValue = ''
  // const label = name || id
  let src = ''
  const summary = data.file && data.file.summary
  console.log(summary)
  const datasetId = data.dataset && data.dataset.id
  const datasetVersionId = data.dataset && data.dataset.version && data.dataset.version.id
  const [url, setUrl] = useState()

  // TODO: TEMPORARY
  useEffect(() => {
    if (!datasetId) return

    async function fetchData() {
      let url = SERVER_URL + '/datasets/' + datasetId
      if (datasetVersionId) {
        url += '/versions/' + datasetVersionId
      }
      url += '.json'

      const response = await fetch(url, {
        credentials: 'include',
        method: 'GET',
      })
      const responseJson = await response.json()
      const fileUrl = responseJson.file.url
      setUrl(fileUrl)
    }
    fetchData()
  }, [datasetId, datasetVersionId])

  if (url) {
    src = url
  } else if (data.file) {
    src = data.file.url
  } else if (data.value) {
    src = `data:image/png;base64,${data.value}`
  } else {
    src = defaultValue
  }
  switch (type) {
    default:
    case 'static': {
      return (
        <div>
          <img
            src={src}
            alt={name}
            // style={{ width: '100%' }}
          />
        </div>
      )
    }
    case 'input': {
      return (
        <TextField
          // label={label}
          value={src}
          variant='filled'
          fullWidth
          multiline
          size='small'
          onChange={event => setData({ value: event.target.value })}
        />
      )
    }
    case 'output': {
      return (
        <div style={{ height: '300px' }}>
          <img
            src={src}
            alt={name}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            // style={{ maxWidth: '100%' }}
          />
        </div>
      )
    }
  }
}

import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'

import FeatureDetails from './FeatureDetails'
import FeatureSearchList from './FeatureSearchList'
// import EditableMapDrawerSortFilter from './EditableMapDrawerSortFilter'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: theme.spacing(4),
    left: theme.spacing(2),
    zIndex: 1,
  },
  paper: {
    height: '80vh',
    width: '280px',
    overflow: 'hidden',
  },
  toggleButton: {
    position: 'absolute',
    height: theme.spacing(6),
    width: theme.spacing(2),
    top: theme.spacing(1),
    right: 0,
    transform: 'translate(100%, 0)',
    padding: 0,
    background: 'white',
    borderLeft: '2px solid gray',
    borderRadius: 0,
    '&:hover': {
      background: 'white',
    },
  },
}))

export const LIST_VIEW = 'LIST'
export const DETAIL_VIEW = 'DETAIL'
export const SORT_FILTER_VIEW = 'SORT_FILTER'
export const INITIAL_VIEW = LIST_VIEW

export default function EditableMapDrawer({
  isOpenDrawer = true,
  setIsOpenDrawer,
  features = [],
  selectedFeatureIndexes, 
  setSelectedFeatureIndexes,
  onSaveFeature = () => {},
  onDeleteFeature = () => {},
  isEditing,
}) {
  const classes = useStyles()
  const [view, setView] = useState(INITIAL_VIEW)
  const [searchText, setSearchText] = useState('')
  const selectedFeature = useMemo(() => {
    if (!selectedFeatureIndexes.length) return null
    const featureIndex = selectedFeatureIndexes[0]
    return { feature: features[featureIndex], index: featureIndex }
  }, [selectedFeatureIndexes, features])

  useEffect(() => {
    if (selectedFeature) {
      setView(DETAIL_VIEW)
    }
  }, [selectedFeature])

  function changeView(view_name) {
    setView(view_name) 
  }

  function getDrawerView() {
    if (view === LIST_VIEW || (!selectedFeature)) {
      return ( 
        <FeatureSearchList
          features={features}
          setSelectedFeatureIndexes={setSelectedFeatureIndexes}
          changeView={changeView}
          searchText={searchText}
          onSearchSubmit={(text) => setSearchText(text)}
        />)
    } else if (view === DETAIL_VIEW) {
        return (<FeatureDetails
          features={features}
          selectedFeature={selectedFeature}
          onSaveDetails={onSaveFeature}
          onDeleteClick={onDeleteFeature}
          onBackClick={() => changeView(LIST_VIEW)}
          isEditing={isEditing}
        />)
      /*
    } else if (view === SORT_FILTER_VIEW) {
        return (
          <EditableMapDrawerSortFilter
            features={features}
            selectedFeatureIndexes={selectedFeatureIndexes}
            onBackClick={() => changeView(LIST_VIEW)}
          />)
          */
    } 
    return null
  }

  function getDrawer() {
    if (!isOpenDrawer) {
      return (
        <IconButton
          onClick={() => setIsOpenDrawer(true)}
          aria-label='close-drawer'
          disableRipple={true}
          size='small'
          className={classes.toggleButton}>
          <ArrowRightIcon style={{ fontSize: '32px' }}/>
        </IconButton>
      )
    }
    return (
      <>
        <Paper className={classes.paper}>
          {getDrawerView()}
        </Paper>
        <IconButton
          onClick={() => setIsOpenDrawer(false)}
          aria-label='close-drawer'
          disableRipple={true}
          size='small'
          className={classes.toggleButton}>
          <ArrowLeftIcon style={{ fontSize: '32px' }}/>
        </IconButton>
      </>
    )
  }

  return (
    <div className={classes.root}>
      {getDrawer()}
    </div>
  )
}

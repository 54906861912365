import { createSlice } from '@reduxjs/toolkit'
import { SERVER_URL } from '../../constants'

export const fetchResultTemplates = resultId => async dispatch => {
  const url = SERVER_URL + '/results/' + resultId + '/templates.json'
  const response = await fetch(url, { credentials: 'include' })
  const responseJson = await response.json()
  dispatch(setResult(responseJson))
}

export const resultSlice = createSlice({
  name: 'result',
  initialState: {},
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
  },
})

export const { set: setResult } = resultSlice.actions

export const getResult = state => state.result

export default resultSlice.reducer

import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Block from '../components/Block'
import { SERVER_URL } from '../constants'
import { makeValidFeature } from '../components/editable-map/EditableMap'

const emptyMapValue = { value : null }

function makeValidData(featureCollection) {
  const { features } = featureCollection
  const validFeatures = features.map((feature) => {
    return makeValidFeature(feature)
  })
  return {
    ...featureCollection,
    features: validFeatures,
  }
}

export default function FileMapScreenPage({
  match: { params: { datasetId } },
}) {
  const [data, setData] = useState(emptyMapValue)
  const [editableData, setEditableData] = useState(emptyMapValue)
  const [
    datasetVersionId, blockKey, projectId,
  ] = window.location.hash.substring(1).split('-')

  useEffect(() => {
    async function fetchData() {
      let url = SERVER_URL + '/datasets/' + datasetId
      if (datasetVersionId) {
        url += '/versions/' + datasetVersionId
      }
      url += '.json'
      // console.log(url)

      // TODO: Consider what to do if 404 from fetch

      const response = await fetch(url, {
        credentials: 'include',
        method: 'GET',
      })
      const responseJson = await response.json()
      const fileUrl = responseJson.file.url
      window.location.hash = responseJson.version.id + '-' + blockKey + '-' + projectId

      const fileResponse = await fetch(fileUrl)
      const fileJson = await fileResponse.json()
      const value = makeValidData(fileJson)
      setData({ value })
      setEditableData({ value })
    }

    fetchData()
  }, [datasetId, datasetVersionId, blockKey, projectId])

  async function saveData() {
    let url = SERVER_URL + '/datasets/' + datasetId + '.json'
    const response = await fetch(url, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'key': blockKey,
        'projects': [{ 'id': projectId }],
      }),
    })
    const {
      version: { id: versionId }, file: { id: fileId, url: fileUrl },
    } = await response.json()

    const blob = new Blob(
      [JSON.stringify(editableData.value)],
      { type: 'application/octet-stream' },
    )

    await fetch(fileUrl, {
      method: 'PUT',
      body: blob,
    })

    await fetch(SERVER_URL + '/files/' + fileId + '.json', {
      credentials: 'include',
      method: 'PATCH',
    })

    console.log('data uploaded to google cloud')
    window.location.hash = versionId + '-' + blockKey + '-' + projectId
  }

  function onSaveClick() {
    console.log('send save request')
    saveData()
  }

  function onCancelClick() {
    console.log('reset data')
    setEditableData(data)
  }

  return (
    <Box display='flex' flexDirection='column' style={{ width: '100vw', height: '100vh' }}>
      <Box flexGrow={1} style={{ background: 'white' }}>
        <Block
          id={'Update data'}
          name={'Update data'}
          view='map'
          type='input'
          setData={(newEditableData) => {
            setEditableData(newEditableData)
          }}
          data={editableData}
          onCancelClick={onCancelClick}
          onSaveClick={onSaveClick}
          fullHeight={true}
        />
      </Box>
    </Box>
  )
}

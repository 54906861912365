import React from 'react'
import Typography from '@material-ui/core/Typography'
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTooltip,
  VictoryTheme,
  VictoryZoomContainer,
} from 'victory'

export default function BarchartView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const value = (data && data.value !== '') ? data.value : {}
  const { xChartKey = '', yChartKey = '', chart = [] } = value

  function getChartDataWithLabel(data) {
    return data.map((datum) => ({
      ...datum,
      label: `${datum[xChartKey]}`,
    }))
  }

  switch (type) {
    default:
    case 'static': {
      return 'Static Barchart View'
    }
    case 'input': {
      return 'input barchart view'
    }
    case 'output': {
      return (
        <div style={{ width: '100%' }}>
          <Typography component='h2' variant='h6'>{ name }</Typography>
          <VictoryChart
            domainPadding={20}
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer />}
          >
            <VictoryAxis />
            <VictoryAxis dependentAxis/>
            <VictoryBar
              data={getChartDataWithLabel(chart)}
              x={xChartKey}
              y={yChartKey}
              labelComponent={<VictoryTooltip />}
            />
          </VictoryChart>
        </div>
      )
    }
  }
}

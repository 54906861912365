import React, { forwardRef, useEffect, useRef, useState } from 'react'
// import { useParams } from 'react-router-dom'
// import Block from '../components/Block'
// import Box from '@material-ui/core/Box'
// import HeaderSection from '../sections/HeaderSection'
// import MainSection from '../sections/MainSection'
import clsx from 'clsx'
import MaterialTable from 'material-table'
// import { MySelect } from './ProjectPage'
import Papa from 'papaparse'

import { makeStyles } from '@material-ui/core/styles'
import AddBox from '@material-ui/icons/AddBox'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'

import { SERVER_URL } from '../constants'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  }

const useStyles = makeStyles(theme => ({
  tableContainer: {
    '& > div:first-child': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& > div:nth-child(2)': {
        flexGrow: 1,
        height: 1,
        '& div': {
          height: '100%',
        },
        '& .MuiTable-root': {
          height: '100%',
        },
      },
    },
  },
}))

export default function FileTableScreenPage({
  match: { params: { datasetId } },
}) {
  const classes = useStyles()
  const [
    headers,
    setHeaders,
  ] = useState([])
	const [
    columns,
    setColumns,
  ] = useState([])
  // const [isDirtyData, setIsDirtyData] = useState(false)
  // const isDirty = useRef()
  const [data, setData] = useState([])
  const rootRef = useRef()
  // const [tableBodyHeight, setTableBodyHeight] = useState(0)
  const [isDirty, setIsDirty] = useState(false)

  // const url = SERVER_URL + '/files/' + fileId + '.json'
  
  const [
    datasetVersionId, blockKey, projectId,
  ] = window.location.hash.substring(1).split('-')
  // console.log('datasetVersionId', datasetVersionId)
  
  useEffect(() => {
    
    async function fetchData() {
      let url = SERVER_URL + '/datasets/' + datasetId
      if (datasetVersionId) {
        url += '/versions/' + datasetVersionId
      }
      url += '.json'
      // console.log(url)

      // TODO: Consider what to do if 404 from fetch

      const response = await fetch(url, {
        credentials: 'include',
        method: 'GET',
      })
      const responseJson = await response.json()
      const fileUrl = responseJson.file.url
      window.location.hash = responseJson.version.id + '-' + blockKey + '-' + projectId

      Papa.parse(fileUrl, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: function (results) {
          // console.log('RESULTS', results)

          // results.data.pop()
          setData(results.data)
          const keys = Object.keys(results.data[0] || [])
          // setHeaders(keys.slice(0,keys.length-1))
          // console.log(keys.filter(_ => _ != 'tableData'))
          setHeaders(keys.filter(_ => _ !== 'tableData'))
          const keysFixed = keys.slice(0,keys.length-1)
          const columnFormattedKeys = keysFixed.map((current) => {
            const obj = {}
            obj['title'] = current
            obj['field'] = current
            return obj
          })
          setColumns(columnFormattedKeys)
        },
      })
    }

    fetchData()
  }, [datasetId, datasetVersionId, blockKey, projectId])


  useEffect(() => {
    // const pushNewData = setInterval(
  const updateDirtyData = async () => {

    // const datasetTimestamp = window.location.hash
    let url = SERVER_URL + '/datasets/' + datasetId + '.json'

    // console.log('HEYY')
    if (!isDirty) return
    // console.log('HEYY IS DIRTY')
    
    // Fetch signed url
    const response = await fetch(url, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'key': blockKey,
        'projects': [{ 'id': projectId }],
      }),
    })
    const { version: { id: versionId }, file: { id: fileId, url: fileUrl } } = await response.json()
    // const responseJson = await response.json()
    // const fileUrl = responseJson.urls.put
    // const fileUrl = responseJson.file.url
    // const fileId = response.Json.file.id
    
    // Get csv
    const unparseOptions = {}
    let newCSVData

    // console.log('HEADERS', headers)
    if (headers.length) {
      unparseOptions['columns'] = headers
      // console.log('XYZ', data, unparseOptions)
      newCSVData = Papa.unparse(data, unparseOptions)
    } else {
      newCSVData = Papa.unparse(data)
    }

    // console.log('DATA', data)
    // console.log('CSV', newCSVData)

    // Build file to sent to google cloud
    const blob = new Blob([newCSVData], { type: 'application/octet-stream' })

    // execute upload file
    await fetch(fileUrl, {
      method: 'PUT',
      body: blob,
    })

    await fetch(SERVER_URL + '/files/' + fileId + '.json', {
      credentials: 'include',
      method: 'PATCH',
    })

    // isDirty.current = false
    setIsDirty(false)

    // console.log('NEW versionId', versionId)
    window.location.hash = versionId + '-' + blockKey + '-' + projectId
  }
  //, 5000)

  updateDirtyData()
    // return () => clearInterval(pushNewData); 
	}, [isDirty, data, headers, datasetId, blockKey, projectId])

  /*
  useEffect(() => {
    if (!rootRef.current) return
    const divChildRef = rootRef.current.children[0]
    const resizeObserverRef = new ResizeObserver((entries) => {
      resizeObserverRef.disconnect() // browser crashes on the fourth update

      const newBodyHeight = getTableBodyHeight(entries[0].target)
      console.log({ newBodyHeight, tableBodyHeight, a: Math.abs(newBodyHeight-tableBodyHeight) })
      if (Math.abs(newBodyHeight-tableBodyHeight) > 100) {
        setTableBodyHeight(newBodyHeight)
      }
    })

    resizeObserverRef.observe(divChildRef)
    
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.unobserve(divChildRef)
      }
    }
  }, [])

  function getTableBodyHeight(el) {
    const materialTableEl = el.children[0]
    const [headerRef, ,footerRef] = materialTableEl.children
    const { height: headerHeight } = headerRef.getBoundingClientRect()
    const { height: footerHeight } = footerRef.getBoundingClientRect()
    const pageSize = window.innerHeight
    const bodyHeight = pageSize - headerHeight - footerHeight

    return bodyHeight 
  }

  // From Polina's code
  <div style={{ width: '100vw', height: '100vh' }} className={ clsx(headers.length > 0 && classes.tableContainer) }>
  */

  return (
    <div style={{ height: '100vh' }} ref={rootRef}>
      <div style={{ maxWidth: '100%', height: '100%' }}className={ clsx(headers.length > 0 && classes.tableContainer) }>
        <MaterialTable
          columns={columns}
          data={data}
					editable={{
						onRowAdd: newData =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									setData([...data, newData])
                  // isDirty.current = true
                  setIsDirty(true)
									resolve()
								}, 1000)
							}),
						onRowUpdate: (newData, oldData) =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									const dataUpdate = [...data]
									const index = oldData.tableData.id
									dataUpdate[index] = newData
									setData([...dataUpdate])
                  // isDirty.current = true
                  setIsDirty(true)
									resolve()
								}, 1000)
							}),
						onRowDelete: oldData =>
							new Promise((resolve, reject) => {
								setTimeout(() => {
									const dataDelete = [...data]
                  // console.log(data)
									const index = oldData.tableData.id
									dataDelete.splice(index, 1)
                  // console.log(dataDelete)
									setData([...dataDelete])
                  // isDirty.current = true
                  setIsDirty(true)
									resolve()
								}, 1000)
							}),
					}}
          icons={tableIcons}
          title="Fullscreen Table"
          options={{
            // minBodyHeight: `${tableBodyHeight}px`,
            // maxBodyHeight: `${tableBodyHeight}px`,
            // minBodyHeight: Date.now() % 2 === 0 ? tableBodyHeight: null,
            // maxBodyHeight: Date.now() % 2 === 0 ? tableBodyHeight: null,
            pageSize: 20,
            pageSizeOptions: [20],
          }}
        />
      </div>
      {/*
      <HeaderSection title='Table File' />
      <Box flexGrow={1} display='flex' flexDirection='column>
        <div>
        </div>
        { false &&
          <Box flexGrow={1} style={{ marginTop: '16px', background: 'white' }}>
          </Box> }
      </Box>
      */}
    </div>
  )
}

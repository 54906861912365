import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { createProject } from '../api/projects'
import RoundBorderButton from './RoundBorderButton'

export default function CreateProjectButton(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [name, setName] = useState()
  const [isOpen, setIsOpen] = useState(false)

  function closeDialog() {
    setIsOpen(false)
  }

  function handleOnCreateProject() {
    dispatch(createProject(name))
      .then((res) => history.push(`/projects/${res.id}`))
  }

  /*
  async function createProject() {
    const url = `${SERVER_URL}/projects.json`
    const data = {
      name: name,
    }
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    const { id } = await response.json()
    history.push(`/projects/${id}`)
  }
  */

  return (
    <div>
      <RoundBorderButton color='primary' onClick={() => setIsOpen(true)}>
        Create a Project
      </RoundBorderButton>
      <Dialog
        fullWidth
        maxWidth='xs'
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby='create-project-dialog-title'
      >
        <DialogTitle id='create-project-dialog-title'>Start Project</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Project Name'
            defaultValue={name}
            onChange={e => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleOnCreateProject}
          >
            Create
          </Button>
          <Button
            onClick={closeDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

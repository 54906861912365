import React, { useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { produce } from 'immer'
import { v4 as uuidv4 } from 'uuid'
import DeckGL from '@deck.gl/react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import {
  DrawPolygonMode,
  DrawLineStringMode,
  DrawPointMode,
  ViewMode,
} from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { StaticMap } from 'react-map-gl'

import { useGetViewState } from '../MapView'
import EditableMapDrawer from './EditableMapDrawer'

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    height: '300px',
    width: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
  editingBtnContainer: {
    position: 'absolute',
    top: theme.spacing(3),
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    '& > *': {
      width: '10ch',
    },
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
  },
  drawModeToolbarContainer: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
}))

const DRAW_MODE_ADD_LINE = 'add > line'
const DRAW_MODE_ADD_POINT = 'add > point'
const DRAW_MODE_ADD_POLYGON = 'add > polygon'

const defaultValue = { type: 'FeatureCollection', features: [] }

function getDrawMode(mode, isEditing) {
  if (!isEditing) return ViewMode
  const mapMode = {
    [DRAW_MODE_ADD_LINE]: DrawLineStringMode,
    [DRAW_MODE_ADD_POINT]: DrawPointMode,
    [DRAW_MODE_ADD_POLYGON]: DrawPolygonMode,
  }[mode]

  return mapMode || ViewMode
}

export function makeValidFeature(feature) {
  return produce(feature, draft => {
    const id = uuidv4()
    const name = id.split('-')[0]
    if (!draft.properties.id) {
      draft.properties.id = id
    }
    if (!draft.properties.name) {
      draft.properties.name = `Feature ${name}`
    }
  })
}

export default function EditableMap({
  data,
  setData,
  onSaveClick = () => {},
  onCancelClick = () => {},
  fullHeight,
}) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [isOpenDrawer, setIsOpenDrawer] = useState(true)
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState([])
  const [drawMode, setDrawMode] = useState(null)

  const value = data.value || defaultValue
  const features = value.features
  const [viewState, setMapRef] = useGetViewState(features)

  const layer = new EditableGeoJsonLayer({
    // id: 'geojson-layer',
    data: value,
    mode: getDrawMode(drawMode, isEditing),
    selectedFeatureIndexes,
    pickable: true,
    autoHighlight: true,
    onClick: handleFeatureMapClick,

    onEdit: ({ updatedData, editType, editContext }) => {
      const { featureIndexes } = editContext
      console.log({ updatedData, editType, editContext })
      if (editType === 'addFeature') {
        // add unique ids
        const featureIndex = featureIndexes[0]
        const newData = produce(updatedData, draft => {
          const features = draft.features
          const feature = features[featureIndex]
          const id = uuidv4()
          const name = id.split('-')[0]
          feature.properties.name = `Feature ${name}`
          feature.properties.id = id // unique id
        })
        setData({ value: newData })
        setSelectedFeatureIndexes([featureIndex])
        setDrawMode(null)
      }
    },
  })

  function handleEditOnClick(e) {
    console.log('edit is clicked')
    setIsEditing(true)
  }

  function handleCancelOnClick(e) {
    console.log('cancel is clicked')
    setIsEditing(false)
    setSelectedFeatureIndexes([])
    onCancelClick()
  }

  function handleSaveOnClick(e) {
    console.log('save is clicked')
    onSaveClick()
    setIsEditing(false)
  }

  function handleFeatureMapClick(info, event) {
    console.log({ info, event, drawMode })
    const { index: featureIndex, isGuide } = info
    if (!drawMode && !isGuide) {
      setSelectedFeatureIndexes([featureIndex])
    }
  }

  function handleOnDeleteFeature(feature, e) {
    const featureIndex = feature.index
    console.log(featureIndex, feature)
    setData(produce(draft => {
      const value = draft.value
      value.features = value.features.filter((feature, index) => {
        return index !== featureIndex
      })
    }))
    setSelectedFeatureIndexes([])
  }

  function handleOnSaveFeatureDetails(feature, newProperties) {
    const featureIndex = feature.index
    console.log(featureIndex, feature)
    setData(produce(draft => {
      const value = draft.value
      value.features[featureIndex].properties = newProperties
    }))
    setSelectedFeatureIndexes(featureIndex)
  }

  return (
    <div
      ref={setMapRef}
      className={clsx(classes.root, fullHeight && classes.fullHeight)}
    >
      <Box className={classes.editingBtnContainer}>
        { isEditing
          ? <>
            <Button
              color='primary'
              variant='contained'
              onClick={handleSaveOnClick}
            >
              Save
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={handleCancelOnClick}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </>
          : (
          <Button
            color='primary'
            variant='contained'
            onClick={handleEditOnClick}
          >
            Edit
          </Button>
        )}
      </Box>
      <EditableMapDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        features={value.features}
        selectedFeatureIndexes={selectedFeatureIndexes}
        setSelectedFeatureIndexes={setSelectedFeatureIndexes}
        onSaveFeature={handleOnSaveFeatureDetails}
        onDeleteFeature={handleOnDeleteFeature}
        isEditing={isEditing}
      />
      { isEditing &&
        <EditableMapDrawModeToolbar setDrawMode={setDrawMode} />
      }
      <div style={{ position: 'relative', height: '100%' }}>
        <DeckGL
          initialViewState={viewState}
          controller={true}
          layers={[layer]}
        >
          <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
        </DeckGL>
      </div>
    </div>
  )
}

export function EditableMapDrawModeToolbar({
  setDrawMode,
}) {
  const classes = useStyles()

  return (
    <div className={classes.drawModeToolbarContainer}>
      <Paper>
        <Button
          variant='outlined'
          onClick={() => setDrawMode(DRAW_MODE_ADD_POINT)}
        >
          Point
        </Button>
        <Button
          variant='outlined'
          onClick={() => setDrawMode(DRAW_MODE_ADD_LINE)}
        >
          Line
        </Button>
        <Button
          variant='outlined'
          onClick={() => setDrawMode(DRAW_MODE_ADD_POLYGON)}
        >
          Polygon
        </Button>
      </Paper>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import JsonEditor from './../JsonEditor'

const useStyles = makeStyles(theme => ({
  detailsRoot: {
    position: 'relative',
    height: '100%',
  },
  appBar: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  jsonEditorContainer: {
    height: '100%',
    '& .jsoneditor': {
      border: 'none',
      color: 'white',
      '& > .jsoneditor-menu': {
        color: 'white !important',
        background: theme.crosscompute.colors.blue,
        borderRadius: theme.spacing(1, 1, 0, 0),
      },
    },
  },
  detailsContent: {
    border: '1px solid #f0f0f0',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
}))

export default function FeatureDetails({
  selectedFeature,
  onSaveDetails = () => {},
  onDeleteClick = () => {},
  onBackClick = () => {},
  isEditing,
}) {
  console.log('FeatureDetails', { selectedFeature })
  const classes = useStyles()
  const { index, feature } = selectedFeature 
  const [isDirty, setIsDirty] = useState(false)
  const [editableProperties, setEditableProperties] = useState(() => {
    console.log({ selectedFeature })
    const { id, ...rest } = feature.properties
    return rest
  })

  useEffect(() => {
    const { id, ...rest } = feature.properties
    setEditableProperties(rest)
    setIsDirty(false)
  }, [feature])

  function onEditorChange(json) {
    console.log('onEditorChange', json)
    setEditableProperties(json)
    setIsDirty(true)
  }

  function onSaveClick() {
    const id = feature.properties.id
    const newProperties = { id, ...editableProperties }
    console.log('onSave', newProperties)
    onSaveDetails(selectedFeature, newProperties)
    setIsDirty(false)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      className={classes.detailsRoot}
    >
      <Grid container>
        <Grid item>
          <div>
            <Button
              startIcon={<ChevronLeftIcon />}
              disableRipple
              onClick={onBackClick}
            >
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Box mr={1} ml={1} mb={2}>
        <Typography variant='h6' component='h2'>Details</Typography>
        <Grid
          container
          spacing={2}
          wrap='nowrap'
          alignItems='center'
        >
          { false &&
            <Grid item>
              <Avatar />
            </Grid>
          }
          <Grid item xs>
            <Typography>{ feature.properties.name}</Typography>
            <Typography color='textSecondary' variant='body2'>{ feature.geometry.type}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.detailsContent} m={.5} style={{ overflow: 'hidden' }}>
        <Box flexGrow={1} style={{ overflow: 'hidden' }}>
          <JsonEditor
            json={editableProperties}
            mode='view'
            name={'Properties'}
            containerProps={{
              className: classes.jsonEditorContainer,
            }}
            onChange={onEditorChange}
            isEditing={isEditing}
          />
        </Box>
        { isDirty && isEditing  &&
          <Button fullWidth onClick={onSaveClick}>Save</Button> }
      </Box>
      <div>
        { isEditing &&
          <Button
            fullWidth
            size='medium'
            className={classes.button}
            onClick={(e) => onDeleteClick(selectedFeature, index, e)}
            variant='contained'
          >
            Delete Feature
          </Button>
        }
      </div>
    </Box>
  )
}

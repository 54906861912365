// TODO: Rename api to servers
import { setUnauthorized } from '../components/auth/authSlice'
import { SERVER_URL } from '../constants'
import store from '../store'

// TODO: Rename to request
export function apiRequest({
  path = '',
  options = {},
}) {
  return new Promise((resolve, reject) => {
    fetch(SERVER_URL + path, {
      ...options,
      mode: 'cors',
      credentials: 'include',
    })
      .then(res => {
        if (res.status === 401) {
          store.dispatch(setUnauthorized())
        } else if (res.ok) {
          resolve(res.json())
        } else {
          // reject(res)
          console.log(res)
        }
      })
      .catch(error => console.log(error))
  })
}

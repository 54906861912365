// TODO: Add case when user is not authenticated
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import { getAuth } from './authSlice'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: '50px',
    height: '50px',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
}))

export default function UserAvatar({ size, showName }) {
  const classes = useStyles()
  const { user } = useSelector(getAuth)
  const sizeClass = classes[size]
  return (
    <>
      <Avatar
        alt={user ? user.name : ''}
        src={user ? user.imageUrl : ''}
        className={sizeClass}
        style={{ margin: '0 0 .8em 0' }}
        classes={{ root: classes.avatar }}
      />
      { showName &&
      <Typography variant='h6' align='center'>
        {user ? user.name: ''}
      </Typography> }
    </>
  )
}

import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
// import Typography from '@material-ui/core/Typography'
// import ImageIcon from '@material-ui/icons/Image'

// import CreateProjectButton from '../components/CreateProjectButton'
import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'

import startProjectImage from '../images/startProject.png'
import { PROJECT_WIZARD_PATH } from '../constants'

const useStyles = makeStyles(theme => ({
  button: {
    'color': 'white',
    'backgroundColor': '#4880ff',
    'borderRadius': '25px',
    'textTransform': 'none',
    'fontWeight': 'bold',
    'fontSize': '1rem',
    'padding': '8px 56px 8px 56px',
    '&:hover': {
      backgroundColor: '#2256ce',
    },
  },
  image: {
    width: '30vw',
    paddingBottom: '.5em',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
}))

export default function EmptyProjectsPage(props) {
  const classes = useStyles()

  return (
    <>
      <MainSection display='flex' flexDirection='column'>
        <HeaderSection title='Projects' />
        <section className={classes.section}>
          <img className={classes.image} src={startProjectImage} alt="workingEngineers" />
          <Button
            variant='contained'
            disableElevation
            className={classes.button}
            component={RouterLink}
            to={PROJECT_WIZARD_PATH}
          >
            Start a project
          </Button>
        </section>
      </MainSection>
    </>
  )
}

import { createSlice } from '@reduxjs/toolkit'

export const toolsSlice = createSlice({
  name: 'tools',
  initialState: [],
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
    add(state, action) {
      state.push(action.payload)
    },
  },
})

export const { set: setTools, add: addTool } = toolsSlice.actions

export const getTools = state => state.tools
export const getToolById = (state) => {
  const tools = getTools(state)
  const toolById = tools.reduce((dict, tool) => {
    const { id } = tool
    dict[id] = tool
    return dict
  }, {})
  return toolById
}

export default toolsSlice.reducer

export const SERVER_URL = process.env.REACT_APP_SERVER_URL
export const LEGACY_URL = process.env.REACT_APP_LEGACY_URL
export const TARGET_URL = process.env.REACT_APP_TARGET_URL

export const ROOT_PATH = '/'
export const SIGN_IN_PATH = '/signin'
export const BOARDS_PATH = '/boards'

export const PROJECTS_PATH = '/projects'
export const PROJECT_PATH = '/projects/:projectId'
export const RESULT_PATH = '/results/:resultId'
export const FORM_PATH = '/forms/:formId'
export const FORM_TEMPLATE_PATH = '/forms/:formId/templates/:templateId'

export const REPORT_PATH = '/reports/:reportId'
export const PROJECT_WIZARD_PATH = '/create-project'
export const PROJECT_EDIT_WIZARD_PATH = '/edit-project/:projectId'

export const PROFILES_PATH = '/profile/:userId'

export const ACKNOWLEDGEMENTS_PATH = '/acknowledgements'

export const PRINTS_PATH = '/prints/:printId'
export const PRINT_DOCUMENT_PATH = '/prints/:printId/documents/:documentIndex'
export const RESULT_TEMPLATE_PATH = '/results/:resultId/templates/:templateId'
export const SETTINGS_PATH = '/settings'
export const AUTHENTICATIONS_PATH = '/authentications'

export const DATASETS_TABLE_FULL_SCREEN_VIEW = '/datasets/:datasetId/views/table'
export const DATASETS_IMAGE_FULL_SCREEN_VIEW = '/datasets/:datasetId/views/image'
export const DATASETS_MAP_FULL_SCREEN_VIEW = '/datasets/:datasetId/views/map'

export const FILES_TABLE_FULL_SCREEN_VIEW = '/files/:fileId/views/table'
export const FILES_MAP_FULL_SCREEN_VIEW = '/files/:fileId/views/map'

export const NAVIGATION_BAR_WIDTH = 88
export const DRAWER_WIDTH = 320
export const NOT_AUTHORIZED_PATH = '/messages/not-authorized'

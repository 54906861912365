import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  roundBorders: {
    borderRadius: theme.spacing(2),
  },
}))

export default function RoundBorderButton(props) {
  const classes = useStyles()
  return (
    <Button {...props} variant='contained' className={classes.roundBorders} />
  )
}

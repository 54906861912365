import React from 'react'
import Typography from '@material-ui/core/Typography'
import BlockTextField from './BlockTextField'

export default function TextView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const summary = data.file && data.file.summary
  const defaultValue = ''
  const value = data.value || summary || defaultValue

  switch (type) {
    default:
    case 'static': {
      return <Typography>{value}</Typography>
    }
    case 'input': {
      return <BlockTextField
        inputProps={{ style: { 'overflow': 'hidden' } }}
        value={value}
        setData={setData}
        rows={5}
        multiline
      />
    }
    case 'output': {
      return <BlockTextField
        // inputProps={{className: classes.root}}
        value={value}
        rows={5}
        multiline
      />
    }
  }
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
// import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import CategoryText from './CategoryText'

const useStyles = makeStyles(theme => ({
  card: {
    'height': '100%',
    'display': 'flex',
    'flexDirection': 'column',
    'overflow': 'hidden',
    'borderBottom': 'blue 3px solid',
    'borderRadius': theme.spacing(1),
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.1)',
    },
  }, 
  cardContent: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  cardActions: {
    flexDirection: 'column',
  },
}))

export default function ProjectCardGridView({ project }) {
  const classes = useStyles()
  const history = useHistory()
  const { name, id } = project
  const projectUrl = `/projects/${id}`

  function getDaysAgo(time) {
    return Math.floor(Math.random() * 10)
  }

  const daysAgo = getDaysAgo()
  return (
    <Card className={classes.card} onClick={() => history.push(projectUrl)}>
      <CardContent className={classes.cardContent}>
        <Typography style={{ wordWrap: 'break-word' }}>
          { name }
        </Typography>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <div style={{ alignSelf: 'flex-start', paddingBottom: '8px' }}>
          <CategoryText size='large'>General</CategoryText>
        </div>
        <div style={{ alignSelf: 'flex-end' }}>
          <Typography color='primary' style={{ fontSize: '.7rem' }}>
            { daysAgo === 0 ? 'Today' : `${daysAgo} days ago`}
          </Typography>
        </div>
      </CardActions>
    </Card>
  )
}

import { apiRequest } from './index'
import {
  setProject,
  updateProjectToolVersion,
  updateProjectDatasetValue,
} from '../components/project/projectSlice'
// import { PROJECT } from '../datasets/project'

export const fetchProject = (id) => async dispatch => {
  return apiRequest({
    path: `/projects/${id}.json`,
  }).then(res => {
    dispatch(setProject(res))
  })
}

export const modifyProject = (project, newData) => async dispatch => {
  const { id, name, tools, results, datasets } = project
  const toolIds = tools.map(tool => tool.id)
  const resultIds = results.map(result => result.id)
  const datasetIds = datasets.map(dataset => dataset.id)

  return apiRequest({
    path: `/projects/${id}.json`,
    options: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, toolIds, resultIds, datasetIds, ...newData }),
    },
  }).then(res => {
    dispatch(setProject(res))
    return res
  })
}

export const fetchProjectToolVersion = (toolId, versionId) => async dispatch => {
  return apiRequest({
    path: `/tools/${toolId}/versions/${versionId}.json`,
  })
    .then(res => dispatch(updateProjectToolVersion(res)))
}

export const fetchProjectDatasetValue = (url, datasetIds) => async dispatch => {
  return fetch(url)
    .then(res => res.json())
    .then(res => {
      // console.log('res', res)
      dispatch(updateProjectDatasetValue({ datasetIds, value: res }))
    })
    .catch(res => res)
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// import { Link as RouterLink, useLocation, Redirect } from 'react-router-dom'
// import { useSelector } from 'react-redux'
// import SaveIcon from '@material-ui/icons/Save'
import Button from '@material-ui/core/Button'
// import { getAuth } from './authSlice'
import GoogleIcon from '../../images/Google-Logo'
import {
  // AUTHENTICATIONS_PATH,
  // ROOT_PATH,
  // SIGN_IN_PATH,
  SERVER_URL,
  TARGET_URL,
} from '../../constants'

/*
const logos = {
  'google': <GoogleIcon />,
}
*/

const useStyles = makeStyles(theme => ({
  button: {
    'background': 'white',
    'border': '1px solid black',
    'textTransform': 'none',
    '&:hover': {
      background: '#d5d5d552',
    },
  },
}))

export default function SignInButton({ props }) {
  const classes = useStyles()
  // const location = useLocation();
  // const { user } = useSelector(getAuth)
  // const { pathname } = location.state || { pathname: ROOT_PATH }
  // const targetUrl = pathname !== SIGN_IN_PATH ? pathname : ROOT_PATH
  const targetUrl = TARGET_URL

  const queryString = `targetUrl=${targetUrl}`
  const signInUrl = SERVER_URL + '/authorizations/enter/google?' + queryString

  return (
    <Button
      fullWidth
      variant='contained'
      disableElevation
      component='a'
      startIcon={<GoogleIcon />}
      href={signInUrl}
      className={classes.button}
    >
      Sign in with Google
    </Button>
  )
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['bytes', 'kb', 'mb', 'gb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const ALPHABET = [
  ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  ...'abcdefghijklmnopqrstuvwxyz',
  ...'0123456789',
]
const ALPHABET_LENGTH = ALPHABET.length

export function getRandomString(length) {
  // https://gist.github.com/6174/6062387#gistcomment-2993079
  return [...Array(length)].map(_ => ALPHABET[
    Math.random() * ALPHABET_LENGTH | 0
  ]).join('')
}

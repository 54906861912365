import { createSlice } from '@reduxjs/toolkit'

export const resultsSlice = createSlice({
  name: 'results',
  initialState: [],
  reducers: {
    set(state, action) {
      Object.assign(state, action.payload)
    },
    add(state, action) {
      state.push(action.payload)
    },
  },
})

export const { set: setResults, add: addResult } = resultsSlice.actions

export const getResult = state => state.result

export default resultsSlice.reducer

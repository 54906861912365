import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { SERVER_URL } from "../constants";
import { setProjectResultFileIdSummary, updateProjectResult } from "../components/project/projectSlice";

export function useEchoesServer(token, setData) {
  const dispatch = useDispatch()
  const echoesServerRef = useRef()

  useEffect(() => {
    const interval = setInterval(() => {
      const isLoading = !token
      if (isLoading || echoesServerRef.current) {
        return
      }
      const echoesUrl = SERVER_URL + '/echoes/' + token + '.json'
      const echoesServer = echoesServerRef.current = new EventSource(echoesUrl)
      console.log('connection established')
      echoesServer.onerror = function (error) {
        // console.log('CONNECTION ERROR')

        const server = echoesServerRef.current
        server.close()
        console.log('connection closed')

        // TODO: Think of a better way to do this
        echoesServerRef.current = null
      }

      function processResultOutputEvent(event) {
        const data = JSON.parse(event.data)
        const resultId = data['r']
        const output = data['<']
        dispatch(updateProjectResult({ resultId, output }))
      }

      function processFileSummaryEvent(event) {
        const data = JSON.parse(event.data)
        const fileId = data['f']
        const fileSummary = data['.']
        setTimeout(() => {
          setData(draft => {
            // TODO: Update only if timestamp is later
            const variableDataId = Object.keys(draft).find(id => {
              const data = draft[id]
              const blockFileId = data.file && data.file.id
              return fileId === blockFileId
            })
            if (variableDataId) {
              const variableData = draft[variableDataId]
              variableData.file.summary = fileSummary
              // !!! Might result in data loss if user edits file
              // after upload before summary
              delete variableData.value
            }
          }, false)


          // TODO: Update project datasets too
          dispatch(setProjectResultFileIdSummary({ fileId, fileSummary }))
        }, 500) // output data might not be available yet
      }

      function processDatasetVersionInputEvent(event) {
        // Update matching block with latest version (fullscreen edit)
        console.log('--------event dataset version input', event)
        /*
        event.data = {
          'd': dataset version id,
          'D': datasetId
          'f': fileId
          '@': block key
          '"': version name
        }
        */
        // TODO: Remember to change the block key if user changes block dataset
        // TODO: => onUpload and onDatasetChange

        const data = JSON.parse(event.data)
        // console.log({ data })
        const datasetId = data['D']
        const datasetVersionId = data['d']
        const fileId = data['f']
        // const fileView = data[':']
        const key = data['@']
        // const datasetVersionName = data['"']
        setData(draft => {
          const variableData = Object.values(draft).find(variableData => {
            return variableData.misc.key === key && variableData.misc.blockType === 'input'
          })
          if (!variableData || !variableData.dataset || variableData.dataset.id !== datasetId) return
          variableData.dataset.version = { 'id': datasetVersionId }
          variableData.file = { 'id': fileId }
        })
        /*
        dispatch(createProjectDatasetVersion({
          id: datasetId,
          version: {
            id: datasetVersionId,
            name: datasetVersionName,
            file: { id: fileId, view: fileView },
          },
        }))
        */
      }

      echoesServer.addEventListener('o', processResultOutputEvent)
      echoesServer.addEventListener('b', processFileSummaryEvent)
      // echoesServer.addEventListener('D', processDatasetInputEvent)
      echoesServer.addEventListener('d', processDatasetVersionInputEvent)
    }, 1000)
    return () => {
      // console.log('CLEAR INTERVAL')
      clearInterval(interval)
    }
  }, [dispatch, token, setData])
}
import { apiRequest } from './index'
import { setAuth } from '../components/auth/authSlice'

export const fetchAuth = () => async dispatch => {
  return apiRequest({
    path: '/authorizations.json',
  }).then(res => {
    dispatch(setAuth(res))
  })
}

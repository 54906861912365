import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  projectCategoryLabel: {
    'display': 'flex',
    'alignItems': 'center',
    'background': theme.crosscompute.colors.blue,
    'fontSize': theme.spacing(1.5),
    'fontWeight': 'bold',
    'color': 'white',
    'padding': theme.spacing(0, 1),
    'borderRadius': theme.spacing(2),
    'marginRight': theme.spacing(1),
  },
}))

export default function CategoryText(props) {
  const classes = useStyles()
  return <Typography className={classes.projectCategoryLabel} {...props} />
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  whiteBox: {
    borderRadius: theme.spacing(.5),
    overflow: 'auto',
    /*
    background: theme.crosscompute.box.background,
    */
    background: 'white',
    height: '100%',
  },
}))

export default function ContentBox(props) {
  const classes = useStyles()
  return  <Box p={4} {...props} className={classes.whiteBox} />
}

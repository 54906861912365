import React, { useState, useEffect, useRef } from 'react'
// import { useParams } from 'react-router-dom'
import Block from '../legacies/Block'

const apiData = {
  blocks: [
    { 'id': 'a', 'view': 'markdown', 'data': '# Title' },
    { 'id': 'b', 'view': 'table', 'data': { 'value': {
      'columns': ['a', 'b'],
      'rows': [[1, 2], [3, 4], [5, 6]],
    } } },
  ],
  styles: [
    { 'body': { 'background-color': 'red', 'color': 'white' } },
    { 'main': { 'background-color': 'orange' } },
    { 'thead th:not(:first-child)': { 'background-color': 'green' } },
    { '#a': { 'background-color': 'red' } },
  ],
}

const fetchData = () => {
  return new Promise(resolve => setTimeout(() => resolve(apiData), 3000))
}

function getCssRules(cssDict) {
  return Object.entries(cssDict).reduce((cssRules, entry, index) => {
    const [property, value] = entry
    return `${cssRules}${property}: ${value};${index === 0 ? ' ' :  ''}`
  }, '')
}

function loadStyles(styles, styleEl) {
  styles.forEach(style => {
    Object.entries(style).forEach(([selector, cssDict]) => {
      const cssRules = getCssRules(cssDict)
      styleEl.sheet.addRule(selector, cssRules)
    })
  })
}

export default function PrintsPage(props) {
  // const { printId } = useParams()
  const styleEl = useRef(null)
  const [blocks, setBlocks] = useState()

  useEffect(() => {
    async function fetchJson() {
      const responseJson = await fetchData()
      const { blocks, styles } = responseJson
      loadStyles(styles, styleEl.current)
      setBlocks(blocks)
    }
    /* 
    document.head.appendChild(styleEl)
    styleEl.sheet.insertRule('body { background: orange }')
    styleEl.sheet.insertRule('h1 { color: blue }')
    */
//    styleEl.current.sheet.insertRule('body { background: orange }')
    fetchJson()
    // styleEl.current.sheet.addRule('body', 'background: red; color: white')
  }, [])

  return (
    <div>
      <style ref={styleEl} />
      {blocks && blocks.map(block => (
        <Block key={block.id} {...block} type='static' />
      ))

      }
    </div>
  )
}

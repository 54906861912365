import React, { useEffect, useState } from 'react'
// import NavigationSection from '../legacies/NavigationSection'
import { SERVER_URL } from '../constants'
import HeaderSection from '../sections/HeaderSection'
import MainSection from '../sections/MainSection'
import Typography from '@material-ui/core/Typography'

export default function SettingsPage(props) {
  // TODO: Handle case when user is not authorized
  const [token, setToken] = useState('')

  useEffect(() => {
    const f = async () => {
      const url = SERVER_URL + '/tokens.json'

      const response = await fetch(url, {
        'credentials': 'include',
      })
      const responseJson = await response.json()
      setToken(responseJson.tokens[0].key)
    }
    f()
  }, [])
  return (
    <MainSection display='flex' flexDirection='column'>
      <HeaderSection title='Settings' />
        <Typography>
          export CROSSCOMPUTE_CLIENT={window.location.origin}<br />
          export CROSSCOMPUTE_SERVER={SERVER_URL}<br />
          export CROSSCOMPUTE_TOKEN={token}
        </Typography>
    </MainSection>
  )
}

import React, {  useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Typography from '@material-ui/core/Typography'

import SearchBar, { FilterDetails } from './SearchBar'
import { 
  SORT_FILTER_VIEW,
} from './EditableMapDrawer' 

function getFilteredFeatures(features, text, properties) {
  const featuresWithIndex = features.map((feature, index) => {
    return { ...feature, index }
  })

  if (!text) return featuresWithIndex

  return featuresWithIndex.filter(feature => {
    const { name } = feature.properties
    return name.toLowerCase().includes(text.toLowerCase())
  })
}

const useItemStyles = makeStyles(theme => ({
  rightIconContainer: {
    padding: 0,
    alignSelf: 'center',
  },
  textCapitize: {
    textTransform: 'capitalize',
  },
}))

export function FeatureListItem({
  feature,
  onItemClick = () => {},
  onSearchSubmit = () => {},
}) {
  const classes = useItemStyles()
  const name = feature.properties.name

  function onClick(e) {
    console.log('onListItemClick')
    onItemClick()
  }

  return (
    <>
      <ListItem style={{ cursor: 'pointer' }}onClick={onClick}>
        { false &&
          <ListItemAvatar>
            <Avatar />
          </ListItemAvatar> }
        <ListItemText
          primary={name}
          secondary={feature.geometry.type}
          className={classes.textCapitize}
        />
        <IconButton
          disableRipple
          className={classes.rightIconContainer}
        >
          <ChevronRightIcon size='small' />
        </IconButton>
      </ListItem>
      <Divider />
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  appBar: {
    background: 'white',
  },
  mainContent: {
    overflowY: 'auto',
    overflowX: 'hidden', 
  },
}))

export default function FeatureSearchList({
  features = [],
  setSelectedFeatureIndexes,
  changeView,
  searchText,
  onSearchSubmit: onSearchBarSubmit,
}) {
  const classes = useStyles()
  const filteredFeatures = useMemo(() => {
    return getFilteredFeatures(features, searchText, '')
  }, [features, searchText])

  function onItemClick(index, e) {
    setSelectedFeatureIndexes([index])
  }

  function getListContent() {
    if (features.length === 0) {
      return (
        <div>
          <Typography variant='h5' component='h1' align='center' color='textSecondary'>
            Empty 
          </Typography>
          <Typography align='center' color='textSecondary'>
            Click on edit to add items
          </Typography>
        </div>
      
      )
    } else if (!filteredFeatures.length) {
      return (
        <div>
          <Typography variant='h5' component='h1' align='center' color='textSecondary'>
            No Match
          </Typography>
          <Typography align='center' color='textSecondary'>
            Please change your search or filter criteria
          </Typography>
        </div>
      )
    }

    return (
      <List style={{ width: '100%', alignSelf: 'flex-start' }}>
        { filteredFeatures.map((feature) => (
          <FeatureListItem
            key={feature.properties.id}
            feature={feature}
            onItemClick={(e) => onItemClick(feature.index, e)}
          /> )) }
      </List>
    )
  }

  return (
    <Box display='flex' flexDirection='column' className={classes.root}>
      <AppBar position='static' color='secondary' className={classes.appBar}>
        <div>
          <SearchBar onSubmit={onSearchBarSubmit} searchText={searchText} />
          <FilterDetails
            items={features}
            filteredItems={filteredFeatures}
            onSortFilterClick={() => changeView(SORT_FILTER_VIEW)}
          />
        </div>
      </AppBar>
      <Box
        flexGrow={1}
        display='flex'
        justifyContent='center'
        alignItems='center'
        className={classes.mainContent}
      >
        {getListContent()}
      </Box>
    </Box>
  )
}

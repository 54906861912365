import React from 'react'
import Typography from '@material-ui/core/Typography'
import BlockTextField from './BlockTextField'

export default function NumberView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const defaultValue = ''
  const value = !(data.value === null || data.value === undefined) ? data.value : defaultValue

  switch (type) {
    default:
    case 'static': {
      return <Typography>{value}</Typography>
    }
    case 'input': {
      return <BlockTextField value={value} setData={setData} />
    }
    case 'output': {
      return <BlockTextField value={value} />
    }
  }
}

import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import {
  ReactComponent as CrossComputeLogo,
} from '../images/CrossCompute-Logo-LargeFormat-20190613.svg'


const useStyles = makeStyles(theme => ({
  logoContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end',
    height: '150px',
  },
}))


export default function AcknowledgementsPage(props) {
  const classes = useStyles()
  return (
    <Container>
      <div className={classes.logoContainer}>
        <CrossComputeLogo width={100}/>
        <Typography variant='h2' style={{ paddingLeft: '8px' }}>CrossCompute</Typography>
      </div>
      <hr />
      <Typography style={{ marginTop: '24px' }} variant='h3' component='h1'>Acknowledgements</Typography>

      <Typography style={{ margin: '24px 0' }}>Thank you to our current contributors:</Typography>
      <ul>
        { [
          { name: 'Miguel Ángel Gordián', url: 'https://github.com/zoek1' },
          { name: 'Marta Moreno', url: 'https://www.linkedin.com/in/marta-moreno-07364b82' },
          { name: 'Samuel Edandison', url: 'https://www.linkedin.com/in/edandison' },
          { name: 'Roy Hyunjin Han', url: 'https://www.linkedin.com/in/invisibleroads' },
        ].map(_ => (
          <li>
            <Typography>
              <Link href={_.url} target='_blank' color='inherit'>{_.name}</Link>
            </Typography>
          </li>
        ))}
      </ul>

      <Typography style={{ margin: '24px 0' }}>Thank you to those who have helped us over the years:</Typography>
      <ul>
        { [
          { name: 'Rodrigo Guarachi', url: 'https://github.com/rmGuarachi' },
          { name: 'Olga Creutzburg', url: 'https://www.linkedin.com/in/olga-creutzburg-52a689b2' },
          { name: 'Salah Ahmed', url: 'https://www.linkedin.com/in/salahspage' },
          { name: 'Polina Chernomaz', url: 'https://www.linkedin.com/in/polinac' },
          { name: 'Ning Wei', url: 'https://www.linkedin.com/in/ning-wei-8152393b' },
          { name: 'Eugen Fomenko', url: 'https://www.linkedin.com/in/eugen-fomenko-57b204168' },
          { name: 'Noé Domínguez Porras', url: 'https://www.linkedin.com/in/noedominguez' },
          { name: 'Shaky Sherpa', url: 'https://www.linkedin.com/in/shakythesherpa' },
          { name: 'Alex Hofmann', url: 'https://www.linkedin.com/in/alex-hofmann-4a006519' },
          { name: 'Ji Yoon Lee', url: 'https://www.linkedin.com/in/ji-yoon-lee-516750136' },
          { name: 'Tyler Doyle', url: 'https://www.linkedin.com/in/tyler-doyle-987642111' },
          { name: 'Ethan Epstein', url: 'https://www.linkedin.com/in/ethan-epstein-80772093' },
          { name: 'Trevor David Rhone', url: 'https://www.linkedin.com/in/trevor-david-rhone-965ba9b' },
          { name: 'Cathaleen Kaiyoorawongs', url: 'https://www.linkedin.com/in/catalina-kaiyoorawongs' },
          { name: 'Jennifer Ruda', url: 'https://www.linkedin.com/in/jruda' },
          { name: 'Yao Kou', url: 'https://www.linkedin.com/in/yaokou' },
          { name: 'Elaine Chan', url: 'https://www.linkedin.com/in/chanelaine' },
          { name: 'Ying Zhou', url: 'https://www.linkedin.com/in/yingznyc' },
          { name: 'Kashfi Fahim', url: 'https://www.linkedin.com/in/kashfifahim' },
          { name: 'Margarita Zias', url: 'https://www.linkedin.com/in/margarita-zias' },
          { name: 'Aida Shoydokova', url: 'https://www.linkedin.com/in/ashoydok' },
          { name: 'Lauren Talbot', url: 'https://www.linkedin.com/in/laurentalbotnyc' },
          { name: 'Benjamin Dean', url: 'https://www.linkedin.com/in/benwdean' },
          { name: 'Catherine Kwan', url: 'https://www.linkedin.com/in/catherine-kwan-27aba827' },
          { name: 'Nicholas O\'Brien', url: 'https://www.linkedin.com/in/njobrien' },
          { name: 'Michael Flowers', url: 'https://www.linkedin.com/in/michael-flowers-8b61601b' },
          { name: 'Matt Basinger', url: 'https://www.linkedin.com/in/matt-basinger-a7b14b35' },
          { name: 'Dorcas Murai', url: 'https://www.linkedin.com/in/dorcas-murai-119813114' },
          { name: 'Chris Horner', url: 'https://www.linkedin.com/in/christopher-hornor-480a913' },
          { name: 'Liliana Durán', url: 'https://www.linkedin.com/in/lumiservicioslilianaduran' },
          { name: 'Ariana Gradow', url: 'https://www.linkedin.com/in/arianagradow' },
          { name: 'Selin Kocaman', url: 'https://www.linkedin.com/in/ayse-selin-kocaman-473023a' },
          { name: 'Rajesh Menon', url: 'https://www.linkedin.com/in/rmeno' },
          { name: 'Jessica Leon', url: 'https://www.linkedin.com/in/jesleon' },
          { name: 'Diego Saenz', url: 'https://www.linkedin.com/in/diegosaenz2010' },
          { name: 'Paul Everitt', url: 'https://www.linkedin.com/in/paeveritt' },
          { name: 'Jake Maslow', url: 'https://www.linkedin.com/in/jake-maslow-4580106' },
          { name: 'Edwin Adkins', url: 'https://www.linkedin.com/in/edwinadkins' },
          { name: 'Chris Natali', url: 'https://www.linkedin.com/in/chris-natali-6841472b' },
          { name: 'Susan Kum', url: 'https://www.linkedin.com/in/susan-s-kum' },
          { name: 'Aly Sanoh', url: 'https://www.linkedin.com/in/sanohaly' },
          { name: 'Vijay Modi', url: 'https://www.linkedin.com/in/vijay-modi-39241229' },
        ].map(company => (
          <li>
            <Typography>
              <Link href={company.url} target='_blank' color='inherit'>{company.name}</Link>
            </Typography>
          </li>
        ))}
      </ul>

      <Typography style={{ margin: '24px 0' }}>Thank you to these organizations:</Typography>
      <ul>
        { [
          { name: 'DevLabs México', url: 'https://www.devlabs.com.mx' },
          { name: 'Python Software Foundation', url: 'https://python.org/psf' },
          { name: 'American Public Power Association', url: 'https://publicpower.org' },
          { name: 'U.S. Department of Energy', url: 'https://www.energy.gov' },
          { name: 'Quadracci Sustainable Engineering Lab at Columbia University', url: 'https://qsel.columbia.edu' },
          { name: 'World Bank', url: 'https://www.worldbank.org' },
          { name: 'Tampa Bay Innovation Center', url: 'https://tbinnovates.com' },
          { name: 'Tech Incubator at Queens College', url: 'https://techincubatorqc.com' },
          { name: 'NYC Mayor\'s Office of Data Analytics', url: 'https://www1.nyc.gov/site/analytics/index.page' },
        ].map(_ => (
          <li>
            <Typography>
              <Link href={_.url} target='_blank' color='inherit'>{_.name}</Link>
            </Typography>
          </li>
        ))}
      </ul>
    </Container>
  )
}

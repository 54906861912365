import { createSlice } from '@reduxjs/toolkit'

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: null,
  reducers: {
    set(state, action) {
      const newState = Object.assign([], action.payload)
      return newState
    },
    addProject(state, action) {
      state.push(action.payload)
    },
    setProject(state, action) {
      const newProject = action.payload
      const projectIndex = state.findIndex(project => project.id === newProject.id)
      if (projectIndex > -1) {
        state[projectIndex] = newProject
      }
    },
    removeProject(state, action) {
      const newProjects = state.filter((project) => project.id !== action.payload)
      return newProjects
    },
  },
})

export const { set: setProjects, addProject, setProject, removeProject } = projectsSlice.actions

export const getProjects = state => {
  const isLoaded = state.projects !== null
  const projects = state.projects || []
  return {
    projects,
    isLoaded,
  }
}
export const getLatestProjects = state => {
  const { projects } = getProjects(state)
  return projects.slice(0, 3).map(project => project)
}
/*
export const getProjectById = (state) => {
  const { projects } = getProjects(state)
  const projectById = projects.reduce((dict, project) => {
    const { id } = project
    dict[id] = project
    return dict
  }, {})
}
*/

export default projectsSlice.reducer

import React from 'react'
import Markdown from 'markdown-to-jsx'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

export default function MarkdownView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const value = (data && data.value !== '') ? data.value : ''

  // console.log('MARKDOWN', data)

  switch (type) {
    default:
    case 'static': {
      return (
        /*
        <Markdown>{value}</Markdown>
        <div style={{ width: '100%' }}>
          <Markdown>
            {value + '\n'}
          </Markdown>
        </div>
        */
        <Markdown>
          {value + '\n'}
        </Markdown>
      )
    }
    case 'input': {
      return (
        <div style={{ width: '100%' }}>
          <Typography>{name}</Typography>
          <div style={{
            height: '150px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            overflow: 'auto',
          }}>
            <div style={{ width: '100%', height: '100%' }}>
              <TextField
                value={value}
                fullWidth
                multiline
                onChange={(e) => setData({ value: e.target.value })}
              />
            </div>
            {false &&
            <div style={{ width: '45%', height: '100%' }}>
              <Markdown>
                { value }
              </Markdown>
            </div>
            }
          </div>
        </div>
      )
    }
    case 'output': {
      return (
        <div style={{ width: '100%' }}>
          <Typography>{name}</Typography>
          <Markdown>
            {value}
          </Markdown>
        </div>
      )
    }
  }
}

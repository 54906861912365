import React from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import UploadIcon from '@material-ui/icons/CloudUpload'

import Block from './Block'
import UploadButton from './UploadButton'
import UploadZone from './UploadZone'
import GetAppIcon from '@material-ui/icons/GetApp'

import { SERVER_URL } from '../constants'
import { addDataset } from '../api/datasets'
import { changeFileStatus } from '../api/files'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(1),
    background: 'white',
  },
}))

export default function ProjectPageBlockCard({
  projectId,
  block,
  isShowUploadButton,
  onFullScreenClick,
  setDataValue,
  setDataFile,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { view, data: { dataset, file } } = block

  const datasetId = dataset && dataset.id
  const datasetVersionId = dataset && dataset.version.id
  
  const fileId = file && file.id

  const { data } = block
  const v = 'value' in data ? data.value : data.file && data.file.summary

  async function handleDownload() {
    const url = SERVER_URL + '/files/' + fileId + '.json'
    const response = await fetch(url, { credentials: 'include' }) 
    const data = await response.json()
    const { url: downloadUrl } = data
    window.location.replace(downloadUrl)
  }

  async function onDrop(acceptedFiles) {
    const acceptedFile = acceptedFiles[0]
    const { type, name, size } = acceptedFile

    const addDatasetResponse = await dispatch(addDataset({
      name, view, type, size, projects: [{ id: projectId }],
    }))

    const { id: datasetId, version, file } = addDatasetResponse
    const versionId = version.id
    const fileId = file.id
    await uploadDataset(file.url, acceptedFile)

    setDataFile({
      dataset: { id: datasetId, version: { id: versionId } },
      file: { id: fileId },
    })
    await dispatch(changeFileStatus({ fileId }))
  }

  async function uploadDataset(url, acceptedFile) {
    const blob = new Blob([acceptedFile], { type: 'application/octet-stream' })
    await fetch(url, {
      method: 'PUT',
      body: blob,
    })
  }

  function Content({ isDragActive }) {
    return (
      <Card className={clsx(classes.wrapper, {
        'crosscompute-blue-background': isDragActive,
      })}>
        <Box display='flex' justifyContent='space-between' alignItems='center' mb={1}>
          <Typography component='h3' variant='h6'>{block.name || block.id}</Typography>
          <Box display='flex' alignItems='flex-end'>
            {isShowUploadButton &&
              <UploadButton onDrop={onDrop}>
                <UploadIcon />
              </UploadButton>
            }

            { data.file &&
              <IconButton
                component='a'
                disabled={!block.data.file}
                onClick={handleDownload}
              >
                <GetAppIcon />
              </IconButton>
            }

            { datasetId &&
              <IconButton
                component={RouterLink}
                disableRipple
                target='_blank'
                disabled={!datasetId}
                onClick={onFullScreenClick}
                to={{
                  pathname: `/datasets/${datasetId}/views/${block.view}#${datasetVersionId}-${block.data.misc.key}-${projectId}`,
                }}
              >
                <FullscreenIcon size='small' />
              </IconButton>
            }
          </Box>
        </Box>
        {/* !!! */}
        {
          typeof v === 'undefined'
          ? <Box display='flex' justifyContent='center' alignItems='center'>
              <CircularProgress />
            </Box>
          : <Block {...block} setData={setDataValue} />
        }
      </Card>
    )
  }
  
  return (isShowUploadButton
    ? <UploadZone Component={Content} onDrop={onDrop} />
    : <Content />
  )
}

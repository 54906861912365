import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import TuneIcon from '@material-ui/icons/Tune'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  inputContainer: {
    border: '1px solid black',
    margin: theme.spacing(1, 1, 0, 1),
    borderRadius: theme.spacing(.5),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 4,
  },
  sortFilterButton: {
    textTransform: 'capitalize',
  },
  grayBackground: {
    background: 'rgba(0, 0, 0, 0.14)',
    borderRadius: 0,
    color: 'white',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.20)',
      borderRadius: 0,
      color: 'white',
    },
  },
}))

export function FilterDetails({
  items,
  filteredItems,
  onSortFilterClick = () => {},
}) {
  const classes = useStyles()
  const totalFilteredFeatures = filteredItems.length

  return (
    <Box display='flex' justifyContent='flex-end' alignItems='center' pl={1} pr={1}>
      <Box>
        <Typography
          component='p'
          color='inherit'
          display='block'
        >
          {totalFilteredFeatures} Found / {items.length} Total
        </Typography>
      </Box>
      { false &&
      <Button
        disableRipple
        startIcon={<TuneIcon />}
        className={classes.sortFilterButton}
        size={'small'}
        onClick={onSortFilterClick}
      >
        Sort & Filter
      </Button> }
    </Box>
  )
}

export default function SearchBar({ onSubmit, searchText }) {
  const classes = useStyles()
  const [filterText, setFilterText] = useState(searchText)

  useEffect(() => {
    setFilterText(searchText)
  }, [searchText])

  function handleOnSubmit(e) {
    onSubmit(filterText)
    e.preventDefault()
  }

  return ( 
    <form onSubmit={handleOnSubmit}>
      <Box display='flex' className={classes.inputContainer}>
        <InputBase
          value={filterText}
          className={classes.input}
          placeholder='Search'
          inputProps={{ 'aria-label': 'search features' }}
          onChange={(e) => {
            setFilterText(e.target.value)
          }}
        />
        { filterText && (
            <IconButton
              variant='contained'
              className={classes.iconButton}
              aria-label='clear input'
              disableRipple
              onClick={(e) => {
                setFilterText('')
                onSubmit('')
                e.preventDefault()
              }}>
              <ClearIcon />
            </IconButton>
          ) }
        <IconButton
          type='submit'
          className={clsx(classes.iconButton, classes.grayBackground)}
          aria-label='search'
          disableRipple
          onClick={(e) => {
            handleOnSubmit(e)
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </form>
  )
}

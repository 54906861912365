import { configureStore } from '@reduxjs/toolkit'
import authReducer from './components/auth/authSlice'
import datasetsReducer from './components/datasets/datasetsSlice'
import resultReducer from './components/result/resultSlice'
import projectReducer from './components/project/projectSlice'
import projectsReducer from './components/projects/projectsSlice'
import toolsReducer from './components/tools/toolsSlice'
import profilesReducer from './components/profile/profilesSlice'
import formReducer from './components/form/formSlice'

export default configureStore({
  reducer: {
    auth: authReducer,
    datasets: datasetsReducer,
    project: projectReducer,
    projects: projectsReducer,
    result: resultReducer,
    tools: toolsReducer,
    profiles: profilesReducer,
    form: formReducer
  },
})

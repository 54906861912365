import { apiRequest } from './index'
import { removeProject, setProjects } from '../components/projects/projectsSlice'
import {setResults} from "../components/results/resultsSlice";
import {setResult} from "../components/result/resultSlice";
import {setForm} from "../components/form/formSlice";

export const createForm = (projectId, resultId, config) => async dispatch => {
  return apiRequest({
    path: '/forms.json',
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ projectId, resultId, ...config }),
    },
  }).then(res => {
    dispatch(setForm(res))
  })
}

export const deleteForm = (id) => async dispatch => {
  return apiRequest({
    path: `/forms/${id}.json`,
    options: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  }).then(res => {
    dispatch(setForm({}))
  }).catch(e => {
    dispatch(setForm({}))
  })
}


export const updateFormAccessPolicy = (id, data) => async dispatch => {
    return apiRequest({
      path: `/policy/forms/${id}.json`,
      options: {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    }).then(res => {
      console.log(res)
      // dispatch(setResults(res))
    }).catch(e => {
      console.log(e)
    })
}

export const fetchForm = (id, data) => async dispatch => {
  return apiRequest({
    path: `/forms/${id}.json`,
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  }).then(res => {
    dispatch(setForm(res))
  })
}

export const fetchManagerForm = (id, data) => async dispatch => {
  return apiRequest({
    path: `/forms/${id}/manager.json`,
    options: {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  }).then(res => {
    dispatch(setForm(res))
  }).catch(e => {
    dispatch(setForm({}))
  })
}

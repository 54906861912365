import React, { useState } from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'
import DeckGL from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { DrawPolygonMode, DrawLineStringMode, DrawPointMode } from '@nebula.gl/edit-modes'
import { EditableGeoJsonLayer } from '@nebula.gl/layers'
import { StaticMap } from 'react-map-gl'

export default function MapView({
  id,
  name,
  type,
  data,
  setData,
}) {
  const value = (data && data.value !== '') ? data.value : { type: 'FeatureCollection', features: [] }

  const layer = new GeoJsonLayer({
    // id: 'geojson-layer',
    data: value,
    // pickable: true,
    // stroked: false,
    // filled: true,
    // extruded: true,
    // lineWidthScale: 20,
    // lineWidthMinPixels: 2,
    // getFillColor: [160, 160, 180, 200],
    // // getLineColor: d => colorToRGBArray(d.properties.color),
    // getRadius: 100,
    // getLineWidth: 1,
    // getElevation: 30
  })

  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

  const INITIAL_VIEW_STATE = {
    longitude: -112.0372,
    latitude: 46.608058,
    zoom: 13,
    pitch: 0,
    bearing: 0,
  }

  const label = name || id

  switch (type) {
    default:
    case 'static': {
      return (
        <div>
          <Typography>{label}</Typography>
          <div style={{ position: 'relative', height: '400px' }}>
            <DeckGL
              initialViewState={INITIAL_VIEW_STATE}
              controller={true}
              layers={[layer]}
            >
              <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
            </DeckGL>
          </div>
        </div>
      )
    }
    case 'input': {
      return (
        <EditableMap
          initialViewState={INITIAL_VIEW_STATE}
          controller={true}
          data={value}
          label={label}
          setData={setData}
        />
      )
    }
    case 'output': {
      return (
        <div style={{ width: '100%' }}>
          <Typography>{label}</Typography>
          <div style={{ position: 'relative', height: '400px' }}>
            <DeckGL
              initialViewState={INITIAL_VIEW_STATE}
              controller={true}
              layers={[layer]}
              onClick={(e) => { console.log('DECKGL', e) } }
            >
              <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
            </DeckGL>
          </div>
        </div>
      )
    }
  }
}

const theme = createMuiTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        'color': '#1A76D1',
        'border': '1px solid #1A76D1',
        '&$selected': {
          color: '#0052a2',
          border: '1px solid #0052a2',
        },
      },
    },
  },
})

export function EditableMap({ initialViewState, controller, data, label, setData }) {
  const selectedFeatureIndexes = []
  // console.log('aaaaaaaaaaaaaaa', data)

  const DRAW_MODE_ADD_LINE = 'add > line'
  const DRAW_MODE_ADD_POINT = 'add > point'
  const DRAW_MODE_ADD_POLYGON = 'add > polygon'

  const [drawMode, setDrawMode] = useState(DRAW_MODE_ADD_LINE)

  const getMapMode = (drawMode) => {
    // console.log('getting the map mode', drawMode)
    const mapMode = {
      [DRAW_MODE_ADD_LINE]: DrawLineStringMode,
      [DRAW_MODE_ADD_POINT]: DrawPointMode,
      [DRAW_MODE_ADD_POLYGON]: DrawPolygonMode,
    }[drawMode]
    return mapMode
  }

  const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN

  const mapMode = getMapMode(drawMode)

  const layer = new EditableGeoJsonLayer({
    // id: 'geojson-layer',
    data: data,
    mode: mapMode,
    selectedFeatureIndexes,

    onEdit: ({ updatedData }) => {
      setData({ value: updatedData })
    },
  })

  // console.log('THE MAP MODE IS', mapMode)
  return (
    <ThemeProvider theme={theme}>
      <div style={{ width: '100%' }}>
        <Typography variant='button'>{label}</Typography>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', top: 0, left: 0, zIndex: '1', background: 'white' }}>
            <ToggleButtonGroup
              color='primary'
              value={drawMode}
              exclusive
            >
              <ToggleButton
                value={DRAW_MODE_ADD_LINE}
                onClick={() => setDrawMode(DRAW_MODE_ADD_LINE)}
              >
                Draw Line
              </ToggleButton>
              <ToggleButton
                value={DRAW_MODE_ADD_POINT}
                onClick={() => setDrawMode(DRAW_MODE_ADD_POINT)}
              >
                Draw Point
              </ToggleButton>
              <ToggleButton
                value={DRAW_MODE_ADD_POLYGON}
                onClick={() => setDrawMode(DRAW_MODE_ADD_POLYGON)}
              >
                Draw Polygon
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div style={{ position: 'relative', height: '400px' }}>
            <DeckGL
              initialViewState={initialViewState}
              controller={true}
              layers={[layer]}
            >
              <StaticMap mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN} />
            </DeckGL>
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}
